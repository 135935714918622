import { SiteConfig } from "../../types";

export const _config: SiteConfig = {
  isDefault: false,
  showTidio: false,
  enableStripeBilling: false,
  serviceName: "English Bisa",
  appLogoPath: "/partner/englishbisa/logo.jpg",
  appLogoHeight: "50px",
  landingLogoPath: "/partner/englishbisa/logo.jpg",
  landingLogoHeight: "60px",
  favicon: "/partner/englishbisa/favicon.png",
  pwaIcons: [
    {
      src: "/partner/englishbisa/pwa-icon.png",
      type: "image/png",
      sizes: "192x192",
    },
  ],
  overrideLanding: true,
  useTokenAuth: false,
  reviewLink: null,
  hubFixedPrice: true,
  hubFacilitatorCanManage: true,
  /// uiLanguage: "pt-BR",
  adminEmail: "hello@englishbisa.com",
  adminPhoneNumber: "+62-815-7417-4622",
  whatsAppNumber: 6281574174622,
  emailsSender: "info@lang-services.com",
  emailInvitationSubject: "You have been invited to English Bisa!",
  emailInvitationText: `
  You have been invited to English Bisa! 🎉
  
  We have created a user for you with the following details:
  Email: {email}
  Password: {password}

  Follow this link to accept the invitation:
  {link}
  `,
  copyright: "English Bisa",
  practiceLanguageOptions: ["en-US", "en-GB", "en-AU", "en-IE", "en-NZ", "en-CA", "en-IN"],
  userLanguageLock: "id-ID",
  showLanguageGuides: true,
  showAccountUsage: true,
  subscriptionDefaults: {
    currency: "usd",
    price: 500,
    characterLimit: 1_050_000,
    productId: "englishbisa-1",
  },
  bioPrompt: `
    You are an English tutor working for English Bisa. 

    About the school:
    Lessons For Life began its success story in Jakarta in 1997. Seeing its success, Lessons For Life opened a Student Center in Bandung in 2000 and Gading Serpong-Tangerang in 2008.
    Our good intention and determination is to provide a QUICK SOLUTION for students to be confident in speaking English in 3 ½ weeks. YOU CAN DO IT !
    Find your final solution at Lessons For Life (englishbisa). 
  `,
  avatarExcludeList: [
    "Microsoft Server Speech Text to Speech Voice (zh-CN, XiaoyouNeural)",
    "Microsoft Server Speech Text to Speech Voice (en-US, AnaNeural)",
  ],
  siteColors: {
    special: {
      desktopSidebar: "#fff",
      desktopSidebarBorderColor: "#e2e8f0",
      desktopSidebarText: "#000",
      desktopSidebarTextHover: "#eee",
      chatAsideMenu: "#383339",
      speakInputIdle: "var(--chakra-colors-brand-primary-700)",
      speakInputRecording: "var(--chakra-colors-brand-primary-800)",
      speakInputRecordingCancel: "var(--chakra-colors-brand-primary-900)",
    },
  },
  fonts: {
    heading: `NasalizationRg-Regular, sans-serif`,
  },
  fontLink: "/partner/englishbisa/NasalizationRg-Regular/stylesheet.css",
  colors: {
    light: {
      main: "#ececec",
      alt: "#ececec",
    },
    primary: {
      main: "#007aff",
      mainContrast: "#fff",
      "50": "#f2f8ff",
      "100": "#c9e3ff",
      "200": "#99caff",
      "300": "#5ba9ff",
      "400": "#3596ff",
      "500": "#0078fb",
      "600": "#0066d4",
      "700": "#0052ab",
      "800": "#004591",
      "900": "#003269",
    },
    secondary: {
      main: "#5a49f8",
      mainContrast: "#fff",
      "50": "#f7f6ff",
      "100": "#e0ddfe",
      "200": "#c5bffd",
      "300": "#a49afb",
      "400": "#9085fa",
      "500": "#7667f9",
      "600": "#5c4cf8",
      "700": "#4a3ccb",
      "800": "#3e33ac",
      "900": "#2d257d",
    },

    tertiary: {
      main: "#5a49f8",
      mainContrast: "#000",
      "50": "#f7f6ff",
      "100": "#e0ddfe",
      "200": "#c5bffd",
      "300": "#a49afb",
      "400": "#9085fa",
      "500": "#7667f9",
      "600": "#5c4cf8",
      "700": "#4a3ccb",
      "800": "#3e33ac",
      "900": "#2d257d",
    },
    // https://palette.saas-ui.dev/
    gray: {
      "50": "#f9fafa",
      "100": "#f1f1f3",
      "200": "#e6e7ea",
      "300": "#d1d4d9",
      "400": "#a8adb6",
      "500": "#787f8e",
      "600": "#4b5568",
      "700": "#2b374d",
      "800": "#132039",
      "900": "#0b1933",
    },
    pink: {
      "50": "#fdf5f9",
      "100": "#f6d9e9",
      "200": "#eebbd7",
      "300": "#e490be",
      "400": "#dc71ab",
      "500": "#d14491",
      "600": "#c61978",
      "700": "#a7045d",
      "800": "#83034a",
      "900": "#630337",
    },
    orange: {
      "50": "#fdfaf7",
      "100": "#f7ebe1",
      "200": "#efd4be",
      "300": "#e2b38b",
      "400": "#d7935c",
      "500": "#cc7630",
      "600": "#be5805",
      "700": "#974704",
      "800": "#773803",
      "900": "#622e03",
    },
    green: {
      "50": "#f6fdf9",
      "100": "#cff3df",
      "200": "#99e6bc",
      "300": "#56d58f",
      "400": "#05c059",
      "500": "#04a54d",
      "600": "#048840",
      "700": "#036a32",
      "800": "#025729",
      "900": "#024821",
    },
    teal: {
      "50": "#f2fcfb",
      "100": "#c5f1ee",
      "200": "#8fe3df",
      "300": "#46d1cb",
      "400": "#05b4ac",
      "500": "#049992",
      "600": "#037c77",
      "700": "#03615c",
      "800": "#02514d",
      "900": "#024340",
    },
    cyan: {
      "50": "#f6fbfd",
      "100": "#d7edf5",
      "200": "#c5e4f1",
      "300": "#b1dbec",
      "400": "#73bfdc",
      "500": "#55b1d5",
      "600": "#31a0cc",
      "700": "#0584b6",
      "800": "#046d96",
      "900": "#035474",
    },
    blue: {
      "50": "#f3f6fa",
      "100": "#d2dfed",
      "200": "#b1c8e0",
      "300": "#8dafd1",
      "400": "#6b97c4",
      "500": "#4d82b8",
      "600": "#2e6cac",
      "700": "#06509b",
      "800": "#004183",
      "900": "#00356b",
    },
  },
};
