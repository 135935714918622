import { SiteConfig } from "../../types";

export const _config: SiteConfig = {
  isDefault: false,
  showTidio: false,
  enableStripeBilling: false,
  serviceName: "LingoMundi",
  pwaServiceName: "LingoMundi",
  appLogoPath: "/partner/iblsprog/logo.png",
  appLogoHeight: "80px",
  landingLogoPath: "/partner/iblsprog/logo-landscape.png",
  landingLogoHeight: "40px",
  hubLogoPath: "/partner/iblsprog/logo.png",
  hubLogoHeight: "60px",
  favicon: "/partner/iblsprog/logo.png",
  pwaIcons: [
    {
      src: "/partner/iblsprog/logo.png",
      type: "image/png",
      sizes: "192x192",
    },
  ],
  overrideLanding: true,
  emailsSender: "info@lang-services.com",
  adminEmail: "mma@iblsprog.dk",
  adminPhoneNumber: "29674638",
  reviewLink: null,
  copyright: "IBL Sprog & Kultur",
  showLanguageGuides: true,
  bioPrompt: `
    You are a language tutor at IBL Sprog & Kultur. We help people connect across cultures by making language learning practical and approachable. Our focus is to give learners the skills and confidence to communicate naturally in everyday and business conversations. 
  `,
  siteColors: {
    special: {
      desktopSidebar: "#243746",
      desktopSidebarBorderColor: "#ddd",
      desktopSidebarText: "#fff",
      desktopSidebarTextHover: "rgba(255, 255, 255, 0.2)",
      chatAsideMenu: "#333",
      mobileNavBackgroundColor: "#333",
      mobileNavBackgroundContrastColor: "#ffffff",
      mobileNavBorderColor: "#333",
      speakInputIdle: "var(--chakra-colors-brand-primary-main)",
      speakInputRecording: "var(--chakra-colors-brand-tertiary-800)",
      speakInputRecordingCancel: "var(--chakra-colors-brand-tertiary-900)",
    },
  },
  colors: {
    primary: {
      main: "#c29e6c",
      mainContrast: "#fff",
      "50": "#faf7f3",
      "100": "#ebdfce",
      "200": "#d9c2a3",
      "300": "#c3a06f",
      "400": "#af8f62",
      "500": "#947852",
      "600": "#7d6645",
      "700": "#645138",
      "800": "#54452f",
      "900": "#3d3222",
    },
    secondary: {
      main: "#c29e6c",
      mainContrast: "#fff",
      "50": "#faf7f3",
      "100": "#ebdfce",
      "200": "#d9c2a3",
      "300": "#c3a06f",
      "400": "#af8f62",
      "500": "#947852",
      "600": "#7d6645",
      "700": "#645138",
      "800": "#54452f",
      "900": "#3d3222",
    },
    tertiary: {
      main: "#c29e6c",
      mainContrast: "#fff",
      "50": "#faf7f3",
      "100": "#ebdfce",
      "200": "#d9c2a3",
      "300": "#c3a06f",
      "400": "#af8f62",
      "500": "#947852",
      "600": "#7d6645",
      "700": "#645138",
      "800": "#54452f",
      "900": "#3d3222",
    },
    gray: {
      "50": "#fafafa",
      "100": "#f1f1f1",
      "200": "#e7e7e7",
      "300": "#d4d4d4",
      "400": "#adadad",
      "500": "#7f7f7f",
      "600": "#545454",
      "700": "#373737",
      "800": "#202020",
      "900": "#191919",
    },
    orange: {
      "50": "#fcfaf8",
      "100": "#f3ece3",
      "200": "#e6d6c2",
      "300": "#d3b894",
      "400": "#c19c6b",
      "500": "#a6865c",
      "600": "#8c714e",
      "700": "#6f5a3e",
      "800": "#584731",
      "900": "#483a28",
    },
    yellow: {
      "50": "#fefefd",
      "100": "#faf9f2",
      "200": "#f0eeda",
      "300": "#e4e0be",
      "400": "#d4cd97",
      "500": "#b2aa63",
      "600": "#8e884f",
      "700": "#6f6a3e",
      "800": "#534f2e",
      "900": "#444126",
    },
    green: {
      "50": "#f8fcfa",
      "100": "#daf0e6",
      "200": "#b2dfcc",
      "300": "#86cdae",
      "400": "#65b693",
      "500": "#579c7e",
      "600": "#488169",
      "700": "#386451",
      "800": "#2e5242",
      "900": "#264337",
    },
    teal: {
      "50": "#f5fbfb",
      "100": "#d6ecee",
      "200": "#b3dcdf",
      "300": "#88c9ce",
      "400": "#63adb2",
      "500": "#549397",
      "600": "#44777b",
      "700": "#355c5f",
      "800": "#2c4d4f",
      "900": "#243f41",
    },
    cyan: {
      "50": "#f7fafc",
      "100": "#dcecf1",
      "200": "#cde3ea",
      "300": "#bbdae3",
      "400": "#87bdcd",
      "500": "#6eafc3",
      "600": "#629eb0",
      "700": "#518392",
      "800": "#426b77",
      "900": "#33535c",
    },
    blue: {
      "50": "#f3f6fa",
      "100": "#d4dfed",
      "200": "#b5c7e0",
      "300": "#92aed2",
      "400": "#7296c5",
      "500": "#6081ac",
      "600": "#506c90",
      "700": "#3d526e",
      "800": "#32435a",
      "900": "#293749",
    },
    purple: {
      "50": "#f8f6fb",
      "100": "#e4dbf0",
      "200": "#cfc1e5",
      "300": "#b29bd6",
      "400": "#9e81cb",
      "500": "#8262b1",
      "600": "#6c5293",
      "700": "#584379",
      "800": "#483763",
      "900": "#352849",
    },
    pink: {
      "50": "#fbf6f8",
      "100": "#f0dce4",
      "200": "#e5bfcf",
      "300": "#d599b3",
      "400": "#c97e9e",
      "500": "#b06283",
      "600": "#975471",
      "700": "#7a445b",
      "800": "#5f3547",
      "900": "#452734",
    },
    red: {
      "50": "#fbf6f6",
      "100": "#f0dcda",
      "200": "#e2bdba",
      "300": "#d29793",
      "400": "#c9817c",
      "500": "#b26963",
      "600": "#965854",
      "700": "#794743",
      "800": "#663c39",
      "900": "#4a2b29",
    },
  },
};
