import { GoogleAnalytics } from "nextjs-google-analytics";

// todo: enable on landing page
// currently hydration error, but check back on plugin if they fixed nextjs 13 issue
// https://github.com/MauricioRobayo/nextjs-google-analytics/issues/304

interface Props {
  gKey: string;
}

export const enableAnalytics =
  typeof window !== "undefined" &&
  window.location.hostname !== "localhost" &&
  process.env.NEXT_PUBLIC_SERVER !== "TEST" &&
  process.env.NEXT_PUBLIC_SERVER !== "BETA";

export default function Analytics(props: Props) {
  if (enableAnalytics) {
    return (
      <div id="analytics">
        <GoogleAnalytics trackPageViews gaMeasurementId={props.gKey} />
      </div>
    );
  }

  return <div />;
}
