import { Box, Container, Flex, Heading, Link, SimpleGrid, Text } from "@chakra-ui/react";
import router from "next/router";
import { useUser } from "../../hooks/use-user";
import { LandingLocale } from "../../localization/landing";
import LanguageSelectorLandingDynamic from "../language-selector-landing-dynamic";
import { signOut } from "next-auth/react";
import { appSignInRedirect } from "../../types/constants";
import { useContext } from "react";
import { IpLocationContext } from "../../hooks/use-location-detector";

export default function FooterLanding(props: { l: LandingLocale; baseLangCode: string }) {
  const user = useUser(false);
  const l = props.l;
  const ipLocation = useContext(IpLocationContext);

  return (
    <Box id="footer" as="footer" backgroundColor="brand.gray.700" color="white" width="100%">
      <Container>
        <SimpleGrid columns={{ base: 1, md: 3, lg: 5 }} spacing={3}>
          <Flex flexDirection={"column"}>
            <Heading fontSize="md" width="100%">
              {l["layout.footer.links"]}
            </Heading>
            <Flex flexDirection={"column"} fontSize="sm" gap={1}>
              <Link flex="1" href="/hub">
                {l["layout.footer.learningHub"]}
              </Link>
              <Link flex="1" href="/#faq">
                {l["layout.footer.faq"]}
              </Link>
              {user.sessionUser ? (
                <Link flex="1" onClick={() => signOut({ callbackUrl: "/" })}>
                  {l["layout.footer.logout"]}
                </Link>
              ) : (
                <Link flex="1" onClick={() => router.push(appSignInRedirect)}>
                  {l["layout.footer.login"]}
                </Link>
              )}
            </Flex>
          </Flex>

          <Flex flexDirection={"column"}>
            <Heading fontSize="md" width="100%">
              {l["layout.footer.pages"]}
            </Heading>
            <Flex flexDirection={"column"} fontSize="sm" gap={1}>
              <Link flex="1" href="/about">
                {l["layout.footer.about"]}
              </Link>
              <Link flex="1" href="/blog">
                {l["layout.footer.blog"]}
              </Link>
              <Link flex="1" href="/legal/terms">
                {l["layout.footer.termsConditions"]}
              </Link>
              <Link flex="1" href="/legal/privacy">
                {l["layout.footer.privacyPolicy"]}
              </Link>
            </Flex>
          </Flex>

          <Flex flexDirection={"column"}>
            <Heading fontSize="md" width="100%">
              {l["layout.footer.partners"]}
            </Heading>
            <Flex flexDirection={"column"} fontSize="sm" gap={1}>
              <Link hidden={ipLocation?.countryCode === "KR"} flex="1" href="/partners/affiliates">
                {l["layout.footer.affiliateProgram"]}
              </Link>
              <Link flex="1" href="/partners/schools">
                {l["layout.footer.schools"]}
              </Link>
              <Link flex="1" href="/partners/business">
                {l["layout.footer.teams"]}
              </Link>
            </Flex>
          </Flex>

          <Flex flexDirection={"column"}>
            <Heading fontSize="md" width="100%">
              {l["layout.footer.tools"]}
            </Heading>
            <Flex flexDirection={"column"} fontSize="sm" gap={1}>
              <Link flex="1" href="/guide/beginner-intro">
                {l["layout.footer.languageGuide"]}
              </Link>
              <Link flex="1" href="https://voicecontrol.chat/">
                Voice Control for ChatGPT
              </Link>
              <Link flex="1" href="https://heymia.ai/">
                Mia AI
              </Link>
              <Link flex="1" href="https://www.talkatype.com/">
                TalkaType
              </Link>
            </Flex>
          </Flex>

          <Flex flexDirection={"column"}>
            <Heading fontSize="md" width="100%">
              {l["layout.footer.contact"]}
            </Heading>
            <Link fontSize="sm" lineHeight={1.4} mb={2} href="mailto:hello@talkio.ai">
              hello@talkio.ai
            </Link>

            <Text mb={2} fontSize="sm" lineHeight={1.4}>
              Aidia ApS <br />
              Pærevangen 15, 1. mf. <br />
              2765 Smørum <br />
              Denmark
            </Text>

            <Text lineHeight={1.4} fontSize="sm">
              {process.env.NEXT_PUBLIC_URL}
            </Text>

            {props.baseLangCode && (
              <Box color="black">
                <LanguageSelectorLandingDynamic defaultBaseLangCode={props.baseLangCode} />
              </Box>
            )}
          </Flex>
        </SimpleGrid>
      </Container>
      <Box width="100%" textAlign="center" my="5" pb={5}>
        <Text fontSize={"xs"}>
          ©Aidia ApS {new Date().getFullYear()}. {l["layout.footer.rights"]}
        </Text>
      </Box>
    </Box>
  );
}
