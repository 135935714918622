import { SiteConfig } from "../../types";

export const _config: SiteConfig = {
  isDefault: false,
  showTidio: false,
  serviceName: "成学路AI",
  appLogoPath: "hidden",
  appLogoHeight: "hidden",
  landingLogoPath: "hidden",
  landingLogoHeight: "hidden",
  useTokenAuth: true,
  uiLanguage: "zh-CN",
  reviewLink: null,
  showLanguageGuides: true,
  enableStripeBilling: false,
};
