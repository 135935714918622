import { LocationResponse } from "pages/api/detect-location";
import { createContext, useEffect, useState } from "react";

export type IpLocation = ReturnType<typeof useLocationDetector>;

export const IpLocationContext = createContext<IpLocation>({} as IpLocation);

export function useLocationDetector(props?: { disable?: boolean }) {
  const [locationData, setLocationData] = useState<LocationResponse>();

  useEffect(() => {
    if (
      window.location.pathname.startsWith("/app") ||
      window.location.pathname.startsWith("/hub")
    ) {
      return;
    }

    async function fetchLocation() {
      const response = await fetch("/api/detect-location");
      const data = await response.json();
      setLocationData(data);
    }

    if (props?.disable) {
      // no need to pay for location detection if not needed
      return;
    }

    fetchLocation();
  }, [props?.disable]);

  return locationData;
}
