import { siteConfig } from "configuration/config";
import { signIn } from "next-auth/react";
import { authTarget } from "types/constants";

export async function login(isLoggedIn: boolean) {
  if (isLoggedIn) {
    window.location.href = "/app";
  } else {
    if (siteConfig.authSignInPath) {
      window.location.href = siteConfig.authSignInPath;
      return;
    }

    signIn(authTarget, { callbackUrl: "/app" });
  }
}
