import { Link, Select, Box, Text } from "@chakra-ui/react";
import { useLanguages } from "../hooks/use-languages";
import { useSettings } from "../hooks/use-settings";

import { curatedLangList } from "../services/speech/static-lang-list";
import { useContext, useEffect, useMemo, useState } from "react";
import { codeToLangName } from "../utils/code-to-lang-name";
import { capitalize } from "../utils/capitalize-first-letter";
import { LocalizationContext } from "../hooks/use-localization";
import { useLanguageLock } from "../hooks/use-language-lock";
import ReactFlagsSelect from "../vendors/react-flag-select";
import { siteConfig } from "../configuration/config";
import { useOrganizationSettings } from "../hooks/use-organization-settings";

interface Props {
  settingsKey: "practiceLanguage" | "userLanguage";
  showFullListLink?: boolean;
  onSelect?: (language: string) => void;
}

const fallbackLang = "en-US";
const fallbackLangSimple = "US";

export default function LanguageSelector(props: Props) {
  const { l } = useContext(LocalizationContext);
  const [showSimpleList, setShowSimpleList] = useState(true);
  const { languages } = useLanguages();
  const { settings, saveSettingsPartial } = useSettings();
  const { lockedPracticeLanguageCode, lockedUserLanguageCode } = useLanguageLock();
  const { organizationSettings, organizationSettingsAreLoading } = useOrganizationSettings();
  // This creates an array of languages, taken from the languages hook, that can be currently utilized by the AI (Main reference for this is the curatedList)
  const availableLanguagesList = languages.filter((c) =>
    organizationSettings?.supportedLanguages?.some((ps) => ps.toLowerCase() === c[0].toLowerCase()),
  );
  const showFullListLink = siteConfig.isDefault && props.showFullListLink;

  const langList = useMemo(() => {
    if (organizationSettingsAreLoading) {
      return [];
    } else {
      const practiceLanguageOptions =
        siteConfig.practiceLanguageOptions || organizationSettings.supportedLanguages;
      if (practiceLanguageOptions?.length && props.settingsKey === "practiceLanguage") {
        return curatedLangList.filter((c) => practiceLanguageOptions?.some((l) => l === c[1]));
      } else if (siteConfig.userLanguageOptions?.length && props.settingsKey === "userLanguage") {
        return curatedLangList.filter((c) =>
          siteConfig.userLanguageOptions?.some((l) => l === c[1]),
        );
      }

      return curatedLangList;
    }
  }, [props.settingsKey, organizationSettings.supportedLanguages, organizationSettingsAreLoading]);

  const simpleCountries = useMemo(() => langList.map(([code]) => code), [langList]);

  const simpleLabels = useMemo(() => {
    const result: Record<string, { primary: string; secondary: string }> = {};

    langList.forEach((c) => {
      result[c[0]] = { primary: c[2], secondary: c[3] };
    });

    return result;
  }, [langList]);

  const selectedLanguage = settings[props.settingsKey] ?? fallbackLang;
  const selectedSimpleLanguage = useMemo(() => {
    const match = langList.find((c) => selectedLanguage.includes(c[1]));
    return match?.[0] || fallbackLangSimple;
  }, [selectedLanguage, langList]);

  useEffect(() => {
    const selectedIsSimpleLang = langList.some((c) => selectedLanguage === c[1]);

    setShowSimpleList(selectedIsSimpleLang || langList.length != 0);
  }, [selectedLanguage, langList]);

  const onSimplifiedSelect = (code: string) => {
    const language = langList.find((c) => c[0] === code) ?? fallbackLang;
    const langCode = language[1];

    saveSettingsPartial({ [props.settingsKey]: langCode });
    props.onSelect?.(langCode);
  };

  const onFullSelect = (code: string) => {
    saveSettingsPartial({ [props.settingsKey]: code });
    props.onSelect?.(code);
  };

  const onToggleDisplay = () => {
    setShowSimpleList(!showSimpleList);
  };

  let lockedLanguageCode: string | null = null;

  if (props.settingsKey === "practiceLanguage" && lockedPracticeLanguageCode) {
    lockedLanguageCode = lockedPracticeLanguageCode;
  } else if (props.settingsKey === "userLanguage" && lockedUserLanguageCode) {
    lockedLanguageCode = lockedUserLanguageCode;
  }

  const lockedLanguageLabel = useMemo(() => {
    const l = languages.filter((l) => l[0] === lockedLanguageCode)[0];
    return l ? l[1] : null;
  }, [languages, lockedLanguageCode]);

  if (lockedLanguageCode) {
    return (
      <Box mt={2} cursor="not-allowed" data-tid="language-locked">
        <Box
          px={4}
          py={2}
          border="1px solid #eee"
          borderRadius={5}
          color="brand.gray.500"
          bg="brand.gray.100"
        >
          {lockedLanguageLabel || capitalize(codeToLangName(lockedLanguageCode) || "")}
        </Box>

        <Text fontSize="2xs" fontWeight="bold" color="brand.gray.400" mt={2}>
          {l["components.languageSelector.managedByOrganization"]}
        </Text>
      </Box>
    );
  }

  return (
    <Box mt={2}>
      {showSimpleList ? (
        <ReactFlagsSelect
          selected={selectedSimpleLanguage}
          onSelect={onSimplifiedSelect}
          placeholder={l["components.languageSelector.selectLanguage"]}
          customLabels={simpleLabels}
          countries={simpleCountries}
          showSecondaryOptionLabel={false}
          showSecondarySelectedLabel={false}
          rfsKey={props.settingsKey}
          searchable
          selectButtonClassName="language-selector"
          fullWidth={true}
        />
      ) : (
        <Select
          id={"select-" + props.settingsKey}
          value={selectedLanguage}
          h={"44px"}
          pb={"5px"}
          onChange={(e) => {
            onFullSelect(e.target.value);
          }}
        >
          {availableLanguagesList.map(([code, name]) => (
            <option value={code} key={code}>
              {name}
            </option>
          ))}
        </Select>
      )}

      {/* Deprecated (!showSimpleList is on for affected users for now) */}
      {showFullListLink && !showSimpleList && (
        <Link
          data-tid={`language-selector-expand-${props.settingsKey}`}
          onClick={onToggleDisplay}
          fontSize="2xs"
          fontWeight="bold"
          color="brand.gray.400"
        >
          {showSimpleList
            ? l["components.languageSelector.fullList"]
            : l["components.languageSelector.simpleList"]}
        </Link>
      )}
    </Box>
  );
}
