import { SessionUser } from "../utils/session-user";
import { siteConfig } from "../configuration/config";

export const theisTalkio = "google-oauth2|101457670124174424596";
export const theisPartner = "auth0|66828bb0369bbc3d56b4a2e4";
export const gregPartner = "auth0|674dc0acedc1f3af37b42a7f";
export const dongpilTalkio = "auth0|672a0d26114210569b7f3ef1";
export const woongTalkio = "auth0|677b6e93b0523ca8124646c7";
export const kierTalkio = "auth0|67345e4f0985250a2d803f8b";

export const godAdmins = [theisTalkio, theisPartner];

export function checkSuperAdminAccess(user?: SessionUser) {
  return (
    !user ||
    process.env.NODE_ENV === "development" ||
    godAdmins.includes(user.id) ||
    user.id === kierTalkio ||
    siteConfig.superAdminIds?.includes(user.id) // Check if user.id is in the superadminList of the sites config
  );
}
