import React from "react";
import { useIdle } from "@custom-react-hooks/use-idle";
import { BiSolidMoon, BiSolidSun } from "react-icons/bi";
import { Box, Text, Flex } from "@chakra-ui/react";

// Debug UI for idle detection -- enable/disable idle detection via hotkeys shift+i+d+l+e
const IdleDetector = () => {
  const isIdle = useIdle(30000);

  return (
    <Box>
      {isIdle ? (
        <Flex gap={2} alignItems={"center"}>
          <BiSolidMoon />
          <Text>Away</Text>
        </Flex>
      ) : (
        <Flex gap={2} alignItems={"center"}>
          <BiSolidSun />
          <Text>Online</Text>
        </Flex>
      )}
    </Box>
  );
};

export default IdleDetector;
