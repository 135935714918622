import { dongpilTalkio, theisTalkio, woongTalkio } from "server/check-super-admin-access";
import { SiteConfig } from "../../types";

export const _config: SiteConfig = {
  isDefault: false,
  enableStripeBilling: false,
  serviceName: "Talkio AI",
  appLogoPath: "/talkio-logo-white.svg",
  appLogoHeight: "22px",
  landingLogoPath: "/talkio-logo.svg",
  landingLogoHeight: "25px",
  favicon: "/favicon.png",
  showTidio: false,
  showLanguageGuides: true,
  adminEmail: "help@talkio.co.kr",
  reviewLink: "https://senja.io/p/talkio/r/9XyUN8",
  overrideLanding: true,
  superAdminIds: [dongpilTalkio, woongTalkio, theisTalkio],
  authErrorPath: "https://kr.talkio.ai/hub/auth/logout/",
  authSignInPath: "https://kr.talkio.ai/hub/auth/login/",
  authSessionDurationHours: 2,
  supportLink: "https://kr.talkio.ai/hub/user/help_reg/",
  hideSignUpLink: true,
  hidePrivacyTosSection: true,
  hideDiscordLink: true,
  uiLanguage: "ko-KR",
  settingsShowLiveFeedback: false,
  pwaIcons: [
    {
      src: "/icons/icon-192x192.png",
      type: "image/png",
      sizes: "192x192",
    },
    {
      src: "/icons/icon-256x256.png",
      type: "image/png",
      sizes: "256x256",
    },
    {
      src: "/icons/icon-512x512.png",
      type: "image/png",
      sizes: "512x512",
    },
  ],
};
