import { Localization, LocalizationContext } from "../hooks/use-localization";
import { TextSelectionContext, useTextSelection } from "../hooks/use-text-selection";
import { ChatStateContext, useChatState } from "../components/chat/hooks/use-chat-state";
import { TtsAudioContext, useTtsAudio } from "../components/chat/hooks/use-tts-audio";
import { useVoiceControl, VoiceControlContext } from "../components/chat/hooks/use-voice-control";
import { FlashcardContext, useFlashcardStateRefetch } from "../hooks/use-flashcard";
import { TutorContext, useTutor } from "../hooks/use-tutor";

interface Props {
  children: React.ReactNode;
  localization: Localization;
}

export default function AppProvider(props: Props) {
  const tutor = useTutor();
  const ttsAudio = useTtsAudio();
  const voiceControl = useVoiceControl(ttsAudio, tutor);
  const textSelection = useTextSelection();
  const chatStateData = useChatState();
  const flashcardStateData = useFlashcardStateRefetch();

  return (
    <>
      <TutorContext.Provider value={tutor}>
        <LocalizationContext.Provider value={props.localization}>
          <TtsAudioContext.Provider value={ttsAudio}>
            <VoiceControlContext.Provider value={voiceControl}>
              <TextSelectionContext.Provider value={textSelection}>
                <ChatStateContext.Provider value={chatStateData}>
                  <FlashcardContext.Provider value={flashcardStateData}>
                    {props.children}
                  </FlashcardContext.Provider>
                </ChatStateContext.Provider>
              </TextSelectionContext.Provider>
            </VoiceControlContext.Provider>
          </TtsAudioContext.Provider>
        </LocalizationContext.Provider>
      </TutorContext.Provider>
    </>
  );
}
