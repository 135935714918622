import { createContext, useMemo, useState } from "react";
import useSWR from "swr";
import { CustomTutorDTO } from "../database/entity/CustomTutor";
import { TutorResponse } from "../pages/api/tutor/[id]";
import { staticCharacterListWithBios } from "../services/characters/static-character-list-with-bios";
import { fetchApi } from "../services/fetch-api";
import { AppError } from "../utils/app-error";
import { logger } from "../utils/logger";
import { useSettings } from "./use-settings";

export type Tutor = ReturnType<typeof useTutor>;

export const TutorContext = createContext<Tutor>({} as Tutor);

export function useTutor() {
  const { settings } = useSettings();
  const [loadingTutor, setLoadingTutor] = useState<boolean>(false);
  const [currentTutor, setCurrentTutor] = useState<CustomTutorDTO>();

  const loadTutor = async (id: number) => {
    try {
      setLoadingTutor(true);
      const tutor = await fetchApi<TutorResponse>(`/tutor/${id}`);
      setCurrentTutor(tutor);

      return tutor;
    } catch (err: any) {
      logger.error("Failed to load tutor", err);
    } finally {
      setLoadingTutor(false);
    }
  };

  const currentTutorVoiceId = useMemo(() => {
    if (!currentTutor) {
      return;
    }

    const voiceCharacters = staticCharacterListWithBios.filter((c) =>
      currentTutor.voiceIds.some((id) => id === c.id),
    );

    const voiceForLang = voiceCharacters.find((c) => c.langCode === settings.practiceLanguage);

    if (voiceForLang) {
      return voiceForLang.id;
    }

    const voiceForGender = staticCharacterListWithBios.find(
      (c) => c.langCode === settings.practiceLanguage && c.gender === voiceCharacters[0]?.gender,
    );

    if (voiceForGender) {
      return voiceForGender.id;
    }

    return settings.voicePreference;
  }, [currentTutor, settings.practiceLanguage, settings.voicePreference]);

  return {
    loadTutor,
    currentTutor,
    loadingTutor,
    currentTutorVoiceId,
  };
}
