export const invitationTemplate = `
<p>Welcome to the<strong> Spanish Language Center!</strong></p>
<p>We're excited to let you know that you've been invited to join our AI Tutor.</p>
<p>We have created a user for you with the following details:</p>
<p><strong>Email address:</strong> {email}</p>
<p><strong>Password:</strong> {password}</p>
<p>We recommend changing the password as soon as possible.</p>
<p>Please click the button to accept your invitation and get started.</p>
<p>&nbsp;</p>
<p><a href="{link}"><img src="https://www.ai.spanish-languagecenter.com/partner/spanish-languagecenter/accept-button.png" alt="" width="260" height="55" /></a></p>
<p>&nbsp;</p>
<p>We look forward to welcoming you!</p>
<p>&nbsp;</p>
<p>Best regards,</p>
<p>Spanish Language Center Miami</p>
<p style="font-family: -apple-system, BlinkMacSystemFont, 'Helvetica Neue', 'Segoe UI', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'; margin: 0px 0px 1em; color: #000000; font-size: 13px; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; white-space: normal; text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial;"><span style="font-size: 11pt; font-family: Calibri, sans-serif;"><strong><img src="https://www.ai.spanish-languagecenter.com/partner/spanish-languagecenter/logo-email.png" alt="" width="103" height="100" /></strong></span></p>
<p style="font-family: -apple-system, BlinkMacSystemFont, 'Helvetica Neue', 'Segoe UI', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'; margin: 0px 0px 1em; color: #000000; font-size: 13px; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; white-space: normal; text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial;"><span style="font-size: 11pt; font-family: Calibri, sans-serif;"><strong>@ <a href="https://www.ai.spanish-languagecenter.com/">ai.spanish-languagecenter.com</a><br /><em>Copyright (C) 2025 Speakifyr - The AI Teacher! All rights reserved.</em></strong></span></p>
<p style="font-family: -apple-system, BlinkMacSystemFont, 'Helvetica Neue', 'Segoe UI', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'; margin: 0px 0px 1em; color: #000000; font-size: 13px; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; white-space: normal; text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial;">&nbsp;</p>
`;
