import en_US1 from "./master1";
import en_US2 from "./master2";
import en_US3 from "./master3";

const en_US = { ...en_US1, ...en_US2, ...en_US3 };

// Function to dynamically import locale files
async function loadLocale(locale: string): Promise<AppLocale> {
  switch (locale) {
    case "en_US":
      return en_US;
    case "da_DK":
      return {
        ...((await import("./generated/da-DK1")).default as unknown as AppLocale),
        ...((await import("./generated/da-DK2")).default as unknown as AppLocale),
        ...((await import("./generated/da-DK3")).default as unknown as AppLocale),
        ...((await import("./overrides/da-DK")).default as unknown as AppLocale),
      };
    case "pt_BR":
      return {
        ...((await import("./generated/pt-BR1")).default as unknown as AppLocale),
        ...((await import("./generated/pt-BR2")).default as unknown as AppLocale),
        ...((await import("./generated/pt-BR3")).default as unknown as AppLocale),
        ...((await import("./overrides/pt-BR1")).default as unknown as AppLocale),
        ...((await import("./overrides/pt-BR2")).default as unknown as AppLocale),
        ...((await import("./overrides/pt-BR3")).default as unknown as AppLocale),
      };
    case "pl_PL":
      return {
        ...((await import("./generated/pl-PL1")).default as unknown as AppLocale),
        ...((await import("./generated/pl-PL2")).default as unknown as AppLocale),
        ...((await import("./generated/pl-PL3")).default as unknown as AppLocale),
        ...((await import("./overrides/pl-PL1")).default as unknown as AppLocale),
        ...((await import("./overrides/pl-PL2")).default as unknown as AppLocale),
      };
    case "zh_CN":
      return {
        ...((await import("./generated/zh-CN1")).default as unknown as AppLocale),
        ...((await import("./generated/zh-CN2")).default as unknown as AppLocale),
        ...((await import("./generated/zh-CN3")).default as unknown as AppLocale),
        ...((await import("./overrides/zh-CN")).default as unknown as AppLocale),
      };
    case "fr_FR":
      return {
        ...((await import("./generated/fr-FR1")).default as unknown as AppLocale),
        ...((await import("./generated/fr-FR2")).default as unknown as AppLocale),
        ...((await import("./generated/fr-FR3")).default as unknown as AppLocale),
        ...((await import("./overrides/fr-FR")).default as unknown as AppLocale),
      };
    case "it_IT":
      return {
        ...((await import("./generated/it-IT1")).default as unknown as AppLocale),
        ...((await import("./generated/it-IT2")).default as unknown as AppLocale),
        ...((await import("./generated/it-IT3")).default as unknown as AppLocale),
        ...((await import("./overrides/it-IT")).default as unknown as AppLocale),
      };
    case "es_MX":
      return {
        ...((await import("./generated/es-MX1")).default as unknown as AppLocale),
        ...((await import("./generated/es-MX2")).default as unknown as AppLocale),
        ...((await import("./generated/es-MX3")).default as unknown as AppLocale),
        ...((await import("./overrides/es-MX")).default as unknown as AppLocale),
      };
    case "jp_JP":
      return {
        ...((await import("./generated/jp-JP1")).default as unknown as AppLocale),
        ...((await import("./generated/jp-JP2")).default as unknown as AppLocale),
        ...((await import("./generated/jp-JP3")).default as unknown as AppLocale),
        ...((await import("./overrides/jp-JP")).default as unknown as AppLocale),
      };
    case "ko_KR":
      return {
        ...((await import("./generated/ko-KR1")).default as unknown as AppLocale),
        ...((await import("./generated/ko-KR2")).default as unknown as AppLocale),
        ...((await import("./generated/ko-KR3")).default as unknown as AppLocale),
        ...((await import("./overrides/ko-KR")).default as unknown as AppLocale),
      };
    case "nl_NL":
      return {
        ...((await import("./generated/nl-NL1")).default as unknown as AppLocale),
        ...((await import("./generated/nl-NL2")).default as unknown as AppLocale),
        ...((await import("./generated/nl-NL3")).default as unknown as AppLocale),
        ...((await import("./overrides/nl-NL")).default as unknown as AppLocale),
      };
    case "he_IL":
      return {
        ...((await import("./generated/he-IL1")).default as unknown as AppLocale),
        ...((await import("./generated/he-IL2")).default as unknown as AppLocale),
        ...((await import("./generated/he-IL3")).default as unknown as AppLocale),
        ...((await import("./overrides/he-IL")).default as unknown as AppLocale),
      };
    case "de_DE":
      return {
        ...((await import("./generated/de-DE1")).default as unknown as AppLocale),
        ...((await import("./generated/de-DE2")).default as unknown as AppLocale),
        ...((await import("./generated/de-DE3")).default as unknown as AppLocale),
        ...((await import("./overrides/de-DE")).default as unknown as AppLocale),
      };
    case "lt_LT":
      return {
        ...((await import("./generated/lt-LT1")).default as unknown as AppLocale),
        ...((await import("./generated/lt-LT2")).default as unknown as AppLocale),
        ...((await import("./generated/lt-LT3")).default as unknown as AppLocale),
        ...((await import("./overrides/lt-LT")).default as unknown as AppLocale),
      };
    default:
      throw new Error(`Unknown locale: ${locale}`);
  }
}

export type AppLocale = typeof en_US;

export const locales: Record<string, () => Promise<AppLocale>> = {
  en_US: () => loadLocale("en_US"),
  pt_BR: () => loadLocale("pt_BR"),
  es_MX: () => loadLocale("es_MX"),
  de_DE: () => loadLocale("de_DE"),
  fr_FR: () => loadLocale("fr_FR"),
  it_IT: () => loadLocale("it_IT"),
  zh_CN: () => loadLocale("zh_CN"),
  jp_JP: () => loadLocale("jp_JP"),
  ko_KR: () => loadLocale("ko_KR"),
  nl_NL: () => loadLocale("nl_NL"),
  he_IL: () => loadLocale("he_IL"),
  pl_PL: () => loadLocale("pl_PL"),
  da_DK: () => loadLocale("da_DK"),
  lt_LT: () => loadLocale("lt_LT"),
};

export type Locale =
  | "en-US"
  | "zh-CN"
  | "pt-BR"
  | "pl-PL"
  | "da-DK"
  | "fr-FR"
  | "it-IT"
  | "es-MX"
  | "jp-JP"
  | "ko-KR"
  | "nl-NL"
  | "he-IL"
  | "de-DE"
  | "lt-LT";

// Function to get UI language selector labels
export async function getUiLanguageSelectorLabels() {
  const labels = await Promise.all(
    Object.entries(locales).map(async ([key, loadLocale]) => {
      const locale = await loadLocale();
      return [key, locale.language];
    }),
  );
  return labels;
}

export const uiLanguageKeys = Object.keys(locales);

export const defaultLocale = en_US;
