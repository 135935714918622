import { createContext, useEffect, useState } from "react";
import { AppLocale, defaultLocale, locales } from "../localization";
import { useSettings } from "./use-settings";
import { langCodeToLocale } from "../utils/lang-code-converters";

export type Localization = ReturnType<typeof useLocalization>;

export const LocalizationContext = createContext<Localization>({} as Localization);

export function useLocalization() {
  const { settings, settingsAreLoading } = useSettings();
  const defaultLocaleKey = langCodeToLocale(settings.uiLanguage);
  const [localeKey, setLocaleKey] = useState<string>(defaultLocaleKey);
  const [locale, setLocale] = useState<AppLocale>(defaultLocale);
  const [localeLoading, setLocaleLoading] = useState(true);

  useEffect(() => {
    if (settingsAreLoading) {
      return;
    }

    setLocaleKey(langCodeToLocale(settings.uiLanguage));
    setLocaleLoading(false);
  }, [settingsAreLoading, settings.uiLanguage]);

  useEffect(() => {
    if (localeLoading) {
      return;
    }

    const localeTarget = locales[localeKey];

    localeTarget().then((locale) => {
      setLocale(locale);
    });
  }, [localeLoading, localeKey, defaultLocaleKey]);

  return { l: locale };
}
