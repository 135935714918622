import {
  Box,
  Button,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Show,
  Text,
} from "@chakra-ui/react";
import { GoChevronRight, GoOrganization } from "react-icons/go";
import { useRouter } from "next/router";

import { useOrganizationSwitcher } from "hooks/use-organization-switcher";

type Props = {
  isAccountSettings?: boolean;
  isHub?: boolean;
};

export default function OrganizationSwitcher({ isAccountSettings, isHub }: Props) {
  const { storageData, organizations, loading, handleOrganizationChange } =
    useOrganizationSwitcher();
  const router = useRouter();

  return storageData && organizations.length > 1 ? (
    <Box
      paddingRight={{
        base: router.pathname.includes("/app/chat") ? 8 : 0,
        lg: 0,
      }}
    >
      <Menu>
        <Show above="md">
          <MenuButton
            textAlign="left"
            as={Button}
            colorScheme={isAccountSettings ? "brand.gray" : ""}
            variant={isAccountSettings ? undefined : "unstyled"}
            bg={isAccountSettings ? undefined : "transparent"}
            size="sm"
            isLoading={loading}
            alignItems="center"
            display="flex"
            leftIcon={<GoOrganization color="special.desktopSidebarText" />}
            rightIcon={<GoChevronRight color="special.desktopSidebarText" />}
            color={isHub ? "white" : "special.desktopSidebarText"}
          >
            <Text
              w={"120px"}
              textAlign="left"
              style={{
                textWrap: "balance",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              {organizations.find((org) => org.id === storageData)?.name || "Select Organization"}
            </Text>
          </MenuButton>
        </Show>
        <Show below="md">
          <MenuButton
            colorScheme="brand.secondary"
            bg="transparent"
            color="special.mobileNavBackgroundContrastColor"
            border="1px solid"
            borderColor="special.mobileNavBackgroundContrastColor"
            mx={5}
            isLoading={loading}
            as={IconButton}
            size={"md"}
            icon={<GoOrganization fontSize="25px" />}
          ></MenuButton>
        </Show>
        <MenuList zIndex={20}>
          {organizations.map((org) => (
            <MenuItem
              role="menuitem"
              key={org.id}
              onClick={() => handleOrganizationChange(org.id)}
              style={{ textWrap: "balance", color: "black" }}
            >
              {org.name}
            </MenuItem>
          ))}
        </MenuList>
      </Menu>
    </Box>
  ) : null;
}
