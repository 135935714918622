const l = {
  "base.and": "and",
  "base.toc": "Terms & Conditions",
  "base.privacyPolicy": "Privacy Policy",
  "base.createAccount": "Create account",
  "base.signin": "Sign in",
  "base.signup": "Sign up",
  "base.logOut": "Log out",

  "authentication.description": "Sign in or create an account",
  "invitation.wrongEmailTitle": "Already logged in",
  "invitation.wrongEmailText":
    "It looks like you're already logged into another account. Please log out and try again.",

  "invitation.invalidLinkTitle": "Invalid invitation",
  "invitation.invalidLinkText": "The invitation link is invalid",
  "invitation.completeSignup": "Please complete the signup to ",
  "invitation.completeSignupDescription":
    "A verification email has been sent to your address. If you do not see it in your inbox, please check your spam folder.",
  "invitation.completeSignupButton": "Complete Signup",
  "invitation.mustSignupTitle": "You have been invited to ",
  "invitation.signInToAccept":
    "Please sign in to accept the invitation. In the invitation email you received, you will find a temporary password.",
  "invitation.acceptTermsOnSignup": "When signing in you automatically accept our",
  "invitation.accept": "Sign In to Accept the Invitation",
  "invitation.failed":
    "Failed to accept invitation. Please try to logout and login - or ask the administrator to invite you again.",
  "invitation.accepted": "Invitation accepted",
  "invitation.emailNotVerified": "Email not verified. Please verify your email and try again.",

  "country.venezuela": "Venezuela",
  "country.japan": "Japan",
  "country.algeria": "Algeria",
  "country.france": "France",
  "country.spain": "Spain",
  "country.china": "China",
  "country.usa": "USA",
  "country.england": "England",
  "country.brazil": "Brazil",
  "country.italy": "Italy",
  "country.denmark": "Denmark",

  "head.title": "Talkio AI | Practice Language with AI",

  "landing.title1": "Practice ",
  "landing.title2": "Language ",
  "landing.title3": "with AI ", // title1 + title2 + title3 = Practice Language with AI
  "landing.subtitle1":
    "The ultimate language training app that uses AI technology to help you improve your oral language skills.",
  "landing.button.freeTrial": "7 days free",
  "landing.creditCardNotice": "Credit card required - cancel any time",
  "landing.text.voiceControlTeam": "From the team behind Voice Control for ChatGPT",
  "landing.text.trustedBy": "Trusted by more than 500,000 users",
  "landing.card.title.advancedAI": "Built with Advanced AI Technology",
  "landing.card.text.advancedAI":
    "Talkio AI is built with advanced AI technology and lets you interact with the AI through voice to train your oral language skills.",
  "landing.card.title.multipleLanguages": "Supports Multiple Languages",
  "landing.card.text.multipleLanguages":
    "We offer a diverse range of language options, including English, Chinese, French, and several other languages.",
  "landing.card.title.premiumVoice": "Premium Voice Technology",
  "landing.card.text.premiumVoice":
    "Talkio AI offers premium voices and supports multiple dialects for the most popular languages. With our advanced language technology, you can immerse yourself in authentic conversations and gain proficiency in the dialects that matter most to you.",
  "landing.sectionTitle.meetTutors": "Meet the AI Tutors",
  "landing.sectionText.moreThanTutors":
    "Talkio AI has more than 400 tutors with unique artificial personalities ready to help you on your language journey.",
  "landing.text.andManyMore": "And many more",
  "landing.text.learnWithAITutors": "Learn with AI Tutors",
  "landing.text.wonderedAboutTutor":
    "Ever wondered how it would be to have a personal language tutor available anytime, anywhere? At Talkio AI, we turn this dream into reality. Our AI Tutors are the perfect companions to improve your oral language skills. Powered by advanced AI technology, they mimic human interaction and conversation, offering an immersive language learning experience that is both engaging and effective.",
  "landing.text.rememberLanguageLearning":
    "Remember, language learning is more than just grammar and vocabulary; it is about expressing yourself confidently. With Talkio AI's intelligent tutors, you can speak, practice, and conquer your language goals!",
  "landing.sectionTitle.featureSpotlight": "Feature Spotlight",
  "landing.sectionText.featureSpotlight":
    "Navigate our suite of features, designed to turn your language learning into an immersive and engaging journey.",
  "landing.text.safeEnvironment": "Safe and Supportive Environment",
  "landing.text.supportiveEnvironment":
    "Our app provides a safe and supportive environment for you to practice speaking with confidence. With Talkio AI, you can have meaningful conversations with the help of AI technology, allowing you to gain fluency and express yourself in the language you are learning.",
  "landing.sectionTitle.insights": "Insights",
  "landing.sectionText.realStories": "Real stories from real users of Talkio AI",
  "landing.sectionTitle.faq": "Frequently Asked Questions",
  "landing.sectionText.faq": "Answers to frequent questions about Talkio AI",
  "landing.sectionTitle.pricing": "Pricing",
  "landing.sectionText.unlockPotential":
    "Unlock your language potential with Talkio. Your journey to mastery starts now.",
  "landing.sectionText.offersForPartners": "We also offer plans for schools and teams",
  "landing.imageAlt.landing": "Landing",
  "landing.imageAlt.laurelLeft": "Laurel left",
  "landing.imageAlt.laurelRight": "Laurel right",
  "landing.imageAlt.speakingWithAI": "Woman speaking with AI",

  "testimonial.text1":
    "I've personally used Talkio AI to improve my Spanish, and let me tell you, it's an incredible option.",
  "testimonial.text2":
    "Talkio AI is a very impressive tool and should be included in every language learner's toolbox.",
  "testimonial.text3": "This tool Talkio AI is blowing my mind for language learning.",
  "testimonial.text4": "Talkio AI - it's an amazing product.",

  "feature.lifeLikeConversations": "Life-like conversations with AI tutors",
  "feature.pronunciationTool": "Pronunciation practice tool",
  "feature.detailedFeedback": "Detailed feedback on your speaking skills",
  "feature.interactiveWordbook": "Interactive wordbook",
  "feature.supportsLanguages": "Supports 40+ languages",

  // Feature Titles and Bullet Points v. 1
  "landing.text.voiceConversations": "Voice Conversations",
  "landing.text.voiceConversationsDescription":
    "Have engaging voice conversations with our AI tutors",
  "landing.text.pronunciation": "Pronunciation",
  "landing.text.pronunciationDescription":
    "Assess and practice your pronunciation with word-by-word feedback",
  "landing.text.feedback": "Feedback",
  "landing.text.feedbackDescription":
    "Get instant feedback on your language skills, and tips on how to improve",
  "landing.text.multipleLanguages": "Multiple Languages and Dialects",
  "landing.text.multipleLanguagesDescription":
    "Choose between 134 languages and dialects to practice your speaking skills",
  "landing.text.progress": "Progress",
  "landing.text.progressDescription":
    "Track your progress and get weekly reports on your language learning journey",
  "landing.text.wordbook": "Wordbook",
  "landing.text.wordbookDescription": "Save and revisit words learned during training",
  "landing.text.streaks": "Streaks",
  "landing.text.streaksDescription":
    "Keep track of your progress with streaks to built a habit of learning",
  "landing.text.crosstalk": "Crosstalk",
  "landing.text.crosstalkDescription":
    "Communicate with the tutor in both your native language and the language you are learning",
  "landing.text.translations": "Translations",
  "landing.text.translationsDescription":
    "Instant translations to support you in your conversations",
  "landing.text.featureSpotlight": "Feature Spotlight",
  "landing.text.featureSpotlightDescription":
    "Navigate our suite of features, designed to turn your language learning into an immersive and engaging journey",

  // Feature Titles and Bullet Points v. 2
  "feature.practiceSpeaking.title": "Practice speaking",
  "feature.practiceSpeaking.bullet1": "Life-like conversations with an AI tutor",
  "feature.practiceSpeaking.bullet2": "Voice-First Interaction",
  "feature.practiceSpeaking.bullet3": "Conversation Help",
  "feature.practiceSpeaking.bullet4": "Repeat & Retry",

  "feature.worldOfLanguages.title": "A World of Languages",
  "feature.worldOfLanguages.bullet1": "Supports 40+ Languages",
  "feature.worldOfLanguages.bullet2": "Instant translations",
  "feature.worldOfLanguages.bullet3": "Multilingual Speech Recognition",
  "feature.worldOfLanguages.bullet4": "Romanization of Script Languages",

  "feature.actionableFeedback.title": "Actionable Feedback",
  "feature.actionableFeedback.bullet1": "Sentence Scoring",
  "feature.actionableFeedback.bullet2": "Improvement Strategies",
  "feature.actionableFeedback.bullet3": "AI Suggestions",
  "feature.actionableFeedback.bullet4": "Speech Precision Score",

  "feature.pronunciationPractice.title": "Pronunciation Practice",
  "feature.pronunciationPractice.bullet1": "Word-by-word Score",
  "feature.pronunciationPractice.bullet2": "Pronunciation Assessment",
  "feature.pronunciationPractice.bullet3": "Accuracy, Completeness & Fluency",
  "feature.pronunciationPractice.bullet4": "Repeat & Retry",

  "feature.interactiveWordbook.title": "Interactive Wordbook",
  "feature.interactiveWordbook.bullet1": "Keep Track of the Words You Learn",
  "feature.interactiveWordbook.bullet2": "Reinforced Memorization via Tutor Repetition",
  "feature.interactiveWordbook.bullet3": "Translation & Read-Aloud",
  "feature.interactiveWordbook.bullet4": "Sentence Examples",

  "feature.button.startTrial": "Start Your Free Trial",

  // FAQ Questions and Answers
  "faq.question1": "Is there a free plan?",
  "faq.answer1": `
      No, all plans are paid. However, there's a 7-day free trial that can be canceled anytime. 
      No strings attached.`,

  "faq.question2": "Who is behind Talkio AI?",
  "faq.answer2.part1": "Talkio AI is a product of",
  "faq.answer2.part2":
    "listed in the European Union. Aidia ApS complies to the General Data Protection Regulation (GDPR) in order to deliver a safe and secure experience for all users.",

  "faq.question3": "Is Talkio AI suitable for beginners?",
  "faq.answer3": `
      Talkio AI is best suited for those with a basic knowledge of a language, aiming to improve their oral skills. For beginners, we have developed an
      introductory guide to get you started. This guide covers fundamental aspects of your chosen language, preparing you to fully benefit from
      Talkio AI. It is free and a great tool for learners starting their journey. Check out the guide and begin enhancing your language skills effectively.`,

  "faq.button.gettingStartedGuide": "Try the FREE getting started guide",

  "faq.question4": "Is payment secure?",
  "faq.answer4":
    "Yes, we use Stripe for financial processing. We do not store any of your credit card information.",

  "faq.question5": "Where is my data stored?",
  "faq.answer5": `Your data is stored on servers in the European Union: 
      Microsoft (voice and data), Auth0 (authentication), Stripe (payments).
      Furthermore, we use services from OpenAI, Anthropic and Groq to power the AI. 
      This data is processed in the United States.`,

  "faq.question6": "Can I get a refund?",
  "faq.answer6": `The free trial can be canceled at any time; however, if you forget to cancel the trial,
      we can refund you within 14 days, provided you haven't used the 
      service after the trial ended. Please contact us at support@talkio.ai`,

  "faq.question7": "What payment methods do you accept?",
  "faq.answer7": `For subscriptions we accept all major credit cards and Paypal. 
      For one-time purchases we also accept WeChat, Przelewy24, Giropay, Alipay and more. 
      Availability depends on your location.`,

  "faq.question8": "Does Talkio have a mobile app?",
  "faq.answer8": `Talkio is a Progressive Web App meaning you can install it from the browser without going to the App Store or Google Play. 
      On Android, while using Talkio in your browser, you will find an installation button after signing up. 
      On iOS, open Talkio in Safari, tap the share icon, and select 'Add to Home Screen' 
      to install it like a regular app on your phone.`,

  "faq.question9": "What browsers can I use?",
  "faq.answer9": `Talkio AI works in all modern browsers, including Chrome, Firefox, Safari, and Edge.
      It also works on mobile browsers on iPhone and Android.`,

  "faq.question10": "What AI models do you use?",
  "faq.answer10": `Talkio AI uses a mix of different AI models from OpenAI, Anthropic, and Meta. 
      Every model has a specific task they excel at and together they create a powerful AI tutor.`,

  "faq.question11": "How is Talkio AI different from Voice Control for ChatGPT?",
  "faq.answer11": `Talkio AI offers a coherent experience with ChatGPT customized for language learning. 
      It also offers more features including pronunciation assessment, premium voice technologies, 
      multilingual support, curated training materials, integrated feedback, and translations.`,

  "faq.question12": "How can I contact you?",
  "faq.answer12": "You are very welcome to write an email to hello@talkio.ai",

  // Landing Ko

  // Service Bar
  "layout.serviceBar.text": "Founded in Denmark. We respect your privacy.",
  "layout.serviceBar.community": "Join a worldwide community of language learners",

  // Navigation Links
  "layout.nav.pricing": "Pricing",
  "layout.nav.faq": "FAQ",
  "layout.nav.languages": "Languages",
  "layout.nav.blog": "Blog",
  "layout.nav.schools": "For Schools",
  "layout.nav.teams": "For Teams",
  "layout.nav.affiliateProgram": "Affiliate program",
  "layout.nav.signIn": "Sign In",
  "layout.nav.goToApp": "Go to app",
  "layout.nav.menu": "Menu",

  // CTA Section
  "layout.cta.description":
    "The ultimate language training app that uses AI technology to help you improve your oral language skills.",
  "layout.cta.button": "Try Talkio AI",

  // Footer Section
  "layout.footer.pages": "Pages",
  "layout.footer.links": "Links",
  "layout.footer.learningHub": "Learning Hub",
  "layout.footer.faq": "FAQ",
  "layout.footer.blog": "Blog",
  "layout.footer.partners": "Partners",
  "layout.footer.tools": "Tools",
  "layout.footer.about": "About",
  "layout.footer.affiliateProgram": "Affiliate program",
  "layout.footer.schools": "Talkio for Schools",
  "layout.footer.teams": "Talkio for Teams",
  "layout.footer.languageGuide": "Free Language Guide",
  "layout.footer.termsConditions": "Terms & Conditions",
  "layout.footer.privacyPolicy": "Privacy Policy",
  "layout.footer.logout": "Logout",
  "layout.footer.login": "Login",

  // Blog Posts
  "layout.footer.blogPosts": "Blog posts",
  "layout.footer.blogPost1": "New Feature on Talkio AI: Interactive Language Guide",
  "layout.footer.blogPost2": "Talkio Integrates Three New Custom GPTs",
  "layout.footer.blogPost3":
    "Elevate Your IELTS Speaking Skills with Talkio AI: A Step-by-Step Guide",
  "layout.footer.blogPost4": "3 Effective Ways to Practice Oral English",
  "layout.footer.blogPost5": "Acing Your English Proficiency Tests",
  "layout.footer.blogPost6": "Mastering TOEFL Speaking with Talkio AI: Your Essential Guide",

  // Contact Section
  "layout.footer.contact": "Contact",
  "layout.footer.rights": "All rights reserved.",
  "layout.footer.illustrationCredits": "Frontpage illustrations by vectorjuice",

  // Pricing Card Descriptions
  "pricing.description.perMonthBilledOnce": "per month - billed once",
  "pricing.description.perMonthBilledQuarterly": "per month - billed quarterly",
  "pricing.description.perMonthBilledBiannually": "per month - billed every 6 months",
  "pricing.description.perMonthBilledYearly": "per month - billed yearly",
  "pricing.description.perMonth": "per month",
  // Pricing Card Buttons
  "pricing.button.buyNow": "Buy Now with 7-Day Money Back Guarantee",
  "pricing.button.startFreeTrial": "Start Your Free Trial",

  // Pricing Card Features
  "pricing.features.refund": "7-day full refund - cancel anytime",
  "pricing.features.freeTrial": "7-day free trial - cancel anytime",
  "pricing.features.supportsLanguages": "Supports 40+ languages",
  "pricing.features.voiceConversations": "Life-like voice conversations",
  "pricing.features.instantFeedback": "Instant feedback",
  "pricing.features.weeklyProgressReport": "Weekly progress report",
  "pricing.features.pronunciationPractice": "Pronunciation practice",
  "pricing.features.interactiveWordbook": "Interactive wordbook",
  "pricing.features.speechRecognition": "Multilingual speech recognition",
  "pricing.features.extraFeatures": "Role-playing, topics, quizzes & more!",

  // Access Request
  "accessRequest.pageNotExist": "404 Page does not exist.",
  "accessRequest.createAccountTitle": "Create an Account to Get Started",
  "accessRequest.createAccountMessage":
    "To join, you will first need to create an account. After signing up, you can request access to the system.",
  "accessRequest.createAccountButton": "Create your account",
  "accessRequest.alreadyHaveAccount": "Already have an account?",
  "accessRequest.signIn": "Sign in",
  "accessRequest.grantedTitle": "Congratulations!",
  "accessRequest.grantedMessage":
    "You have successfully gained access to {serviceName}. Click below to start using the app.",
  "accessRequest.goToApp": "Go to App",
  "accessRequest.accountExists": "Account Already Exists",
  "accessRequest.accountExistsMessage":
    "You are already a member with this account. To continue, please log out and sign up with a different email.",
  "accessRequest.logOut": "Log Out",
  "accessRequest.requestAccessTitle": "Request Access to Continue",
  "accessRequest.requestAccessMessage":
    "Your request will need to be approved before you can get access.",
  "accessRequest.checkBackLater": "Please check back later to see if your access has been granted.",
  "accessRequest.submitRequest": "Submit Request",
  "accessRequest.pending": "Access Request Pending",
};

export default l;
