import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalHeader,
  Box,
  Spinner,
  Flex,
} from "@chakra-ui/react";
import { useContext, useEffect, useState } from "react";

import LanguageSelector from "./language-selector";
import { LocalizationContext } from "../hooks/use-localization";

type Props = {
  practiceLanguageNeedsConfig: boolean;
  isLoadingVoiceSelection: boolean;
};

export default function LanguageConfigPromptModal(props: Props) {
  const { l } = useContext(LocalizationContext);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setIsOpen(props.practiceLanguageNeedsConfig);
  }, [props.practiceLanguageNeedsConfig]);

  return (
    <Modal
      id="language_config_prompt_modal"
      closeOnOverlayClick={false}
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
    >
      <ModalOverlay />
      <ModalContent p={5}>
        <ModalHeader textAlign={"center"}>
          {l["config.languageConfigPromptModal.title"]}
        </ModalHeader>
        <ModalBody fontSize="sm" lineHeight="1.6">
          <Box>{l["config.languageConfigPromptModal.body"]}</Box>
          <Box mt={5} mb={2}>
            {props.isLoadingVoiceSelection ? (
              <Flex my="5" justifyContent={"center"} alignContent={"center"}>
                <Spinner />
              </Flex>
            ) : (
              <LanguageSelector settingsKey="practiceLanguage" showFullListLink />
            )}
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
