import { useState, useEffect, useContext } from "react";
import { useRouter } from "next/router";
import { Organization } from "../database/entity/Organization";
import { fetchApi } from "../services/fetch-api";
import { useSimpleToast } from "./use-simple-toast";
import { UserPreferredOrganizationSavedResponse } from "../types/user";
import { LocalizationContext } from "./use-localization";
import { useSessionStorage } from "./use-session-storage";
import { useUser } from "./use-user";

export function useOrganizationSwitcher() {
  const user = useUser();
  const { setStorageData, storageData, clearStorage } = useSessionStorage(
    "preferredOrganization",
    user.systemUser?.primaryOrganization?.id,
  );
  const router = useRouter();
  const { l } = useContext(LocalizationContext);
  const { toastSuccess, toastFail } = useSimpleToast();
  const [loading, setLoading] = useState<boolean>(false);
  const [organizations, setOrganizations] = useState<Organization[]>([]);

  useEffect(() => {
    if (user.systemUser?.primaryOrganization?.id && user.systemUser?.organizations?.length > 1) {
      setStorageData(user.systemUser?.primaryOrganization?.id);
      setOrganizations(user.systemUser?.organizations || []);
    }
  }, [setStorageData, user.systemUser?.organizations, user.systemUser?.primaryOrganization?.id]);

  const handleOrganizationChange = async (orgId: number) => {
    setLoading(true);
    setStorageData(orgId);

    try {
      const response = await fetchApi<UserPreferredOrganizationSavedResponse>(
        `/user/select-preferred-organization`,
        {
          method: "PUT",
          body: JSON.stringify({ preferredOrganizationId: orgId }),
        },
      );

      if (response.ok) {
        setStorageData(orgId);

        if (router.pathname.includes("/hub")) {
          window.location.href = `/hub/organizations/${orgId}`;
        } else {
          window.location.href = "/app";
        }

        // toastSuccess(l["components.organizationSwitcher.save.success"]);
      } else {
        toastFail(l["components.organizationSwitcher.save.failed"]);
      }
    } catch (error) {
      toastFail(l["components.organizationSwitcher.save.unexpectedError"]);
    } finally {
      setLoading(false);
    }
  };

  return {
    storageData,
    organizations,
    loading,
    handleOrganizationChange,
    clearStorage,
  };
}
