import { enableAnalytics } from "components/analytics";
import mixpanel, { Dict } from "mixpanel-browser";
import { useEffect } from "react";
import { logger } from "../utils/logger";
import { useUser } from "./use-user";

const token =
  process.env.NEXT_PUBLIC_ENVIRONMENT === "production" && process.env.NEXT_PUBLIC_SERVER !== "TEST"
    ? "5f2613aa7c7f70994c53f6c6454cebd5"
    : "e16630bf270e17538cb7a0ac66300bd2";

export function useMixpanel(shouldInit = false) {
  const { systemUser } = useUser(false);

  const trackMixpanelEvent = (name: string, properties?: Dict) => {
    enableAnalytics && mixpanel.track(name, properties);
  };

  useEffect(() => {
    if (shouldInit && enableAnalytics) {
      logger.info("init mixpanel");
      mixpanel.init(token, {
        debug: false,
        track_pageview: true,
        persistence: "localStorage",
      });

      mixpanel.register({
        "Browser Language": window?.navigator?.language || "Unknown",
      });
    }
  }, [shouldInit]);

  useEffect(() => {
    if (systemUser?.id) {
      enableAnalytics && mixpanel?.identify(systemUser.id);
    }
  }, [systemUser?.id]);

  return { trackMixpanelEvent };
}
