export const invitationTemplateFallback = `
<table class="nl-container" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; background-color: #ffffff;" role="presentation" border="0" width="100%" cellspacing="0" cellpadding="0">
<tbody>
<tr>
<td>
<table class="row row-1" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;" role="presentation" border="0" width="100%" cellspacing="0" cellpadding="0" align="center">
<tbody>
<tr>
<td>
<table class="row-content stack" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; color: #000000; width: 600px; margin: 0 auto;" role="presentation" border="0" width="600" cellspacing="0" cellpadding="0" align="center">
<tbody>
<tr>
<td class="column column-1" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; padding-bottom: 5px; padding-top: 5px; vertical-align: top; border: 0px;" width="100%">
<table class="paragraph_block block-1" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; word-break: break-word;" role="presentation" border="0" width="100%" cellspacing="0" cellpadding="20">
<tbody>
<tr>
<td class="pad" style="color: #333333; direction: ltr; font-family: Arial, 'Helvetica Neue',                                      Helvetica, sans-serif; font-size: 16px; font-weight: 400; letter-spacing: 0px; line-height: 120%; text-align: left; mso-line-height-alt: 19.2px;">
<p style="margin: 0; margin-bottom: 16px;">Cześć,,</p>
<p style="margin: 0; margin-bottom: 16px;">Z radością informujemy, że zostałeś zaproszony do dołączenia do {serviceName}.&nbsp;</p>
<div>Wygląda na to, że masz już konto powiązane z tym adresem e-mail.</div>
<div>&nbsp;</div>
<div>Kliknij poniższy przycisk i użyj swoich aktualnych danych logowania, aby zaakceptować zaproszenie.</div>
</td>
</tr>
</tbody>
</table>
<table class="button_block block-2" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;" role="presentation" border="0" width="100%" cellspacing="0" cellpadding="10">
<tbody>
<tr>
<td class="pad" align="center"><a style="color: #ffffff; background-color: #589c7c; border-radius: 4px; display: inline-block; font-family: Arial, 'Helvetica Neue',                                        Helvetica, sans-serif; font-size: 16px; font-weight: 400; mso-border-alt: none; padding-bottom: 5px; padding-top: 5px; text-align: center; text-decoration: none; width: auto; word-break: keep-all; border: 0px solid transparent;" href="{link}" target="_blank"> <span style="word-break: break-word; padding-left: 40px; padding-right: 40px; font-size: 16px; display: inline-block; letter-spacing: normal; line-height: 32px;">Akceptuję zaproszenie</span></a></td>
</tr>
</tbody>
</table>
<table class="paragraph_block block-3" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; word-break: break-word;" role="presentation" border="0" width="100%" cellspacing="0" cellpadding="20">
<tbody>
<tr>
<td class="pad" style="color: #333333; direction: ltr; font-family: Arial, 'Helvetica Neue',                                      Helvetica, sans-serif; font-size: 16px; font-weight: 400; letter-spacing: 0px; line-height: 120%; text-align: left; mso-line-height-alt: 19.2px;">
<p style="margin: 0;">Jeśli nie pamiętasz hasła, możesz utworzyć nowe <a style="text-decoration: underline; color: #406592;" href="{resetPasswordLink}" target="_blank" rel="noopener">tutaj </a></p>
</td>
</tr>
</tbody>
</table>
<table class="paragraph_block block-3" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; word-break: break-word;" role="presentation" border="0" width="100%" cellspacing="0" cellpadding="20">
<tbody>
<tr>
<td class="pad" style="color: #333333; direction: ltr; font-family: Arial, 'Helvetica Neue',                                      Helvetica, sans-serif; font-size: 16px; font-weight: 400; letter-spacing: 0px; line-height: 120%; text-align: left; mso-line-height-alt: 19.2px;">
<p style="margin: 0;"><strong>{organizationName}</strong></p>
</td>
</tr>
</tbody>
</table>
<table class="divider_block block-4" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;" role="presentation" border="0" width="100%" cellspacing="0" cellpadding="40">
<tbody>
<tr>
<td class="pad" align="center">
<table style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;" role="presentation" border="0" width="100%" cellspacing="0" cellpadding="0">
<tbody>
<tr>
<td class="divider_inner" style="font-size: 1px; line-height: 1px; border-top: 1px solid #dddddd;"><span style="word-break: break-word;"> &lt;/span &gt; </span></td>
</tr>
</tbody>
</table>
</td>
</tr>
</tbody>
</table>
<table class="paragraph_block block-5" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; word-break: break-word;" role="presentation" border="0" width="100%" cellspacing="0" cellpadding="10">
<tbody>
<tr>
<td class="pad" style="color: #333333; direction: ltr; font-family: Arial, 'Helvetica Neue',                                      Helvetica, sans-serif; font-size: 14px; font-weight: 400; letter-spacing: 0px; line-height: 120%; text-align: center; mso-line-height-alt: 16.8px;">
<p style="margin: 0; margin-bottom: 5px;"><strong>{serviceName}</strong></p>
<p style="margin: 0;">&nbsp;</p>
</td>
</tr>
</tbody>
</table>
</td>
</tr>
</tbody>
</table>
</td>
</tr>
</tbody>
</table>
</td>
</tr>
</tbody>
</table>
<div id="gtx-trans" style="position: absolute; left: 13px; top: 320.422px;">
<div class="gtx-trans-icon">&nbsp;</div>
</div>
`;
