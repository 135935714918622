import { codeToEnglishLangName, codeToLangNameShort } from "../../utils/code-to-lang-name";
import { SupportedLang } from "./text-to-speech-config";

/**
 * Cross-talk languages
 * Source: https://github.com/MicrosoftDocs/azure-docs/blob/main/articles/ai-services/speech-service/includes/release-notes/release-notes-tts.md#july-2023-release
 */
export const multiLingualLanguages = [
  "ar-EG", // Arabic (Egypt)
  "ar-SA", // Arabic (Saudi Arabia)
  "ca-ES", // Catalan
  "cs-CZ", // Czech (Czechia)
  "da-DK", // Danish (Denmark)
  "de-AT", // German (Austria)
  "de-CH", // German (Switzerland)
  "de-DE", // German (Germany)
  "en-AU", // English (Australia)
  "en-CA", // English (Canada)
  "en-GB", // English (United Kingdom)
  "en-HK", // English (Hong Kong SAR)
  "en-IE", // English (Ireland)
  "en-IN", // English (India)
  "en-US", // English (United States)
  "es-ES", // Spanish (Spain)
  "es-MX", // Spanish (Mexico)
  "fi-FI", // Finnish (Finland)
  "fr-BE", // French (Belgium)
  "fr-CA", // French (Canada)
  "fr-CH", // French (Switzerland)
  "fr-FR", // French (France)
  "hi-IN", // Hindi (India)
  "hu-HU", // Hungarian (Hungary)
  "id-ID", // Indonesian (Indonesia)
  "it-IT", // Italian (Italy)
  "ja-JP", // Japanese (Japan)
  "ko-KR", // Korean (Korea)
  "nb-NO", // Norwegian Bokmål (Norway)
  "nl-BE", // Dutch (Belgium)
  "nl-NL", // Dutch (Netherlands)
  "pl-PL", // Polish (Poland)
  "pt-BR", // Portuguese (Brazil)
  "pt-PT", // Portuguese (Portugal)
  "ru-RU", // Russian (Russia)
  "sv-SE", // Swedish (Sweden)
  "th-TH", // Thai (Thailand)
  "tr-TR", // Turkish (Türkiye)
  "zh-CN", // Chinese (Mandarin, Simplified)
  "yue", // Chinese (Cantonese, Traditional)
  "zh-TW", // Chinese (Taiwanese Mandarin, Traditional)
];

type Country = string;
type LangCode = string;
type LangName = string;
type SearchString = string;

export const curatedLangList: [Country, LangCode, LangName, SearchString][] = [
  ["US", "en-US", "American English", "American English"],
  ["GB", "en-GB", "British English", "British English, England, UK"],
  ["EI", "en-IE", "Irish English", "Irish English, Ireland, Hiberno"],
  ["AU", "en-AU", "Australian English", "Australian English, Australia"],
  ["NZ", "en-NZ", "New Zealand English", "New Zealand English, New Zealand"],
  ["CE", "en-CA", "Canadian English", "Canadian English, Canada"],
  ["ED", "en-IN", "Indian English", "Indian English, India"],

  // ["en-KE", "English (Kenya)", "locale"],
  // ["en-NG", "English (Nigeria)", "locale"],
  // ["en-NZ", "English (New Zealand)", "locale"],
  // ["en-PH", "English (Philippines)", "locale"],
  // ["en-SG", "English (Singapore)", "locale"],
  // ["en-TZ", "English (Tanzania)", "locale"],
  // ["en-ZA", "English (South Africa)", "locale"],

  ["FR", "fr-FR", "Français", "Français, French, France"],
  ["CA", "fr-CA", "Français canadien", "Français, French, Canada"],
  ["FC", "fr-CH", "Français suisse", "Français, French, Switzerland"],
  ["DE", "de-DE", "Deutsch", "Deutsch (Deutschland), German, Germany"],
  ["AT", "de-AT", "Österreichisches Deutsch", "Österreichisches Deutsch, German, Austria"],
  ["CH", "de-CH", "Schweizer Hochdeutsch", "Schweizer Hochdeutsch, German, Germany"],
  ["BR", "pt-BR", "Português", "Português, Portuguese, Brazil"],
  ["PT", "pt-PT", "Português", "Português, Portuguese, Portugal"],
  ["CN", "zh-CN", "中文（中国）", "中文（中国）, Chinese, China"],
  [
    "TW",
    "zh-TW",
    "中文（台灣）",
    "中文（台灣）, Taiwanese Mandarin, Taiwan, Chinese, China, Traditional",
  ],
  ["HK", "zh-HK", "中文（香港）", "Cantonese, Hong Kong, Chinese, China"],

  ["ES", "es-ES", "Español de España", "Español, Spanish, Spain"],
  ["MX", "es-MX", "Español de México", "Español, Spanish, Mexico"],
  ["AR", "es-AR", "Español de Argentina", "Español, Spanish, Argentina"],
  ["BO", "es-BO", "Español de Bolivia", "Español, Spanish, Bolivia"],
  ["CL", "es-CL", "Español de Chile", "Español, Spanish, Chile"],
  ["CO", "es-CO", "Español de Colombia", "Español, Spanish, Colombia"],
  ["CR", "es-CR", "Español de Costa Rica", "Español, Spanish, Costa Rica"],
  ["CU", "es-CU", "Español de Cuba", "Español, Spanish, Cuba"],
  ["EC", "es-EC", "Español de Ecuador", "Español, Spanish, Ecuador"],
  ["SV", "es-SV", "Español de El Salvador", "Español, Spanish, El Salvador"],
  ["GT", "es-GT", "Español de Guatemala", "Español, Spanish, Guatemala"],
  ["GQ", "es-GQ", "Español de Guinea Ecuatorial", "Español, Spanish, Equatorial Guinea"],
  ["UE", "es-US", "Español de Estados Unidos", "Español, Spanish, United States"],
  ["HN", "es-HN", "Español de Honduras", "Español, Spanish, Honduras"],
  ["NI", "es-NI", "Español de Nicaragua", "Español, Spanish, Nicaragua"],
  ["PA", "es-PA", "Español de Panamá", "Español, Spanish, Panama"],
  ["PY", "es-PY", "Español de Paraguay", "Español, Spanish, Paraguay"],
  ["PE", "es-PE", "Español de Perú", "Español, Spanish, Peru"],
  ["PR", "es-PR", "Español de Puerto Rico", "Español, Spanish, Puerto Rico"],
  ["DO", "es-DO", "Español de República Dominicana", "Español, Spanish, Dominican Republic"],
  ["UY", "es-UY", "Español de Uruguay", "Español, Spanish, Uruguay"],
  ["VE", "es-VE", "Español de Venezuela", "Español, Spanish, Venezuela"],

  ["EG", "ar-EG", "العربية", "العربية (مصر), Arabic, Egypt"],
  ["JO", "ar-JO", "العربية", "العربية (الأردن), Arabic, Jordan"],
  ["AE", "ar-AE", "العربية", "العربية (الإمارات العربية المتحدة), Arabic, United Arab Emirates"],
  ["BH", "ar-BH", "العربية", "العربية (البحرين), Arabic, Bahrain"],
  ["DZ", "ar-DZ", "العربية", "العربية (الجزائر), Arabic, Algeria"],
  ["IQ", "ar-IQ", "العربية", "العربية (العراق), Arabic, Iraq"],
  ["KW", "ar-KW", "العربية", "العربية (الكويت), Arabic, Kuwait"],
  ["MA", "ar-MA", "العربية", "العربية (المغرب), Arabic, Morocco"],
  ["SA", "ar-SA", "العربية", "العربية (المملكة العربية السعودية), Arabic, Saudi Arabia"],
  ["YE", "ar-YE", "العربية", "العربية (اليمن), Arabic, Yemen"],
  ["TN", "ar-TN", "العربية", "العربية (تونس), Arabic, Tunisia"],
  ["SY", "ar-SY", "العربية", "العربية (سوريا), Arabic, Syria"],
  ["OM", "ar-OM", "العربية", "العربية (عُمان), Arabic, Oman"],
  ["QA", "ar-QA", "العربية", "العربية (قطر), Arabic, Qatar"],
  ["LB", "ar-LB", "العربية", "العربية (لبنان), Arabic, Lebanon"],
  ["LY", "ar-LY", "العربية", "العربية (ليبيا), Arabic, Libya"],

  ["ZA", "af-ZA", "Afrikaans", "Afrikaans (Suid-Afrika)"],
  ["AL", "sq-AL", "Albanian", "Albanian (Albania)"],
  // ["AM", "hy-AM", "Armenian", "Armenian (Armenia)"],
  ["AZ", "az-AZ", "Azərbaycan", "Azərbaycan (Azerbaijan)"],
  ["EU", "eu-ES", "Basque", "Basque (Spain)"],
  ["BA", "bs-BA", "Bosanski", "Bosanski (Bosnia & Herzegovina)"],
  // ["MM", "my-MM", "Burmese", "Burmese (Myanmar [Burma])"],
  ["CT", "ca-ES", "Català", "Català (Espanya) Catalonia, Catalan, Spain"],
  ["CZ", "cs-CZ", "Čeština", "Čeština (Česko)"],
  ["DK", "da-DK", "Dansk", "Dansk (Danmark)"],
  ["EE", "et-EE", "Eesti", "Eesti (Eesti)"],
  ["PH", "fil-PH", "Filipino", "Filipino (Pilipinas)"],
  ["GC", "gl-ES", "Galician", "Galician (Spain)"],
  ["GE", "ka-GE", "Georgian", "Georgian (Georgia)"],
  ["HR", "hr-HR", "Hrvatski", "Hrvatski (Hrvatska)"],
  ["IS", "is-IS", "Icelandic", "Icelandic (Iceland)"],
  ["ID", "id-ID", "Indonesian", "Indonesian (Indonesia)"],
  ["IE", "ga-IE", "Irish", "Irish (Ireland)"],
  ["IT", "it-IT", "Italiano", "Italiano (Italia)"],
  ["JV", "jv-ID", "Javanese", "Javanese (Indonesia), Java, Indonesian"],
  ["KZ", "kk-KZ", "Kazakh", "Kazakh (Kazakhstan)"],
  // ["KH", "km-KH", "Khmer", "Khmer (Cambodia)"],
  ["KE", "sw-KE", "Kiswahili", "Kiswahili (Kenya)"],
  ["TZ", "sw-TZ", "Kiswahili", "Kiswahili (Tanzania)"],
  // ["LA", "lo-LA", "Lao", "Lao (Laos)"],
  ["LV", "lv-LV", "Latviešu", "Latviešu (Latvija)"],
  ["LT", "lt-LT", "Lietuvių", "Lietuvių (Lietuva)"],
  ["MK", "mk-MK", "Macedonian", "Macedonian (North Macedonia)"],
  ["HU", "hu-HU", "Magyar", "Magyar (Magyarország), Hungarian, Hungary"],
  ["MT", "mt-MT", "Maltese", "Maltese (Malta)"],
  ["MY", "ms-MY", "Melayu", "Melayu (Malaysia)"],
  ["MN", "mn-MN", "Mongolian", "Mongolian (Mongolia)"],
  ["NL", "nl-NL", "Nederlands", "Nederlands (Nederland)"],
  ["NP", "ne-NP", "Nepali", "Nepali (Nepal)"],
  ["NO", "nb-NO", "Norsk bokmål", "Norsk bokmål (Norge)"],
  ["UZ", "uz-UZ", "O‘zbek", "O‘zbek (Uzbekistan)"],
  ["AF", "ps-AF", "Pashto", "Pashto (Afghanistan)"],
  ["PL", "pl-PL", "Polski", "Polski (Polska), Polish"],
  ["RO", "ro-RO", "Română", "Română (România)"],

  ["LK", "si-LK", "Sinhala", "Sinhala (Sri Lanka)"],
  ["SK", "sk-SK", "Slovenčina", "Slovenčina (Slovensko)"],
  ["SI", "sl-SI", "Slovenščina", "Slovenščina (Slovenija)"],
  ["SO", "so-SO", "Somali", "Somali (Somalia)"],
  ["FI", "fi-FI", "Suomi", "Suomi (Suomi)"],
  ["SE", "sv-SE", "Svenska", "Svenska (Sverige)"],
  ["VN", "vi-VN", "Tiếng Việt", "Tiếng Việt (Việt Nam)"],
  ["TR", "tr-TR", "Türkçe", "Türkçe (Türkiye), Turkey"],
  ["BE", "nl-BE", "Vlaams", "Vlaams"],
  ["WL", "cy-GB", "Welsh", "Welsh (United Kingdom), Wales"],
  ["ZZ", "zu-ZA", "Zulu", "Zulu (South Africa)"],
  ["GR", "el-GR", "Ελληνικά", "Ελληνικά (Ελλάδα), Greek"],
  ["BG", "bg-BG", "Български", "Български (България), Bulgarian"],
  ["RU", "ru-RU", "Русский", "Русский (Россия), Russian"],
  ["RS", "sr-RS", "Српски", "Српски (Србија), Serbian"],
  ["UA", "uk-UA", "Українська", "Українська (Україна), Ukrainian"],
  ["IL", "he-IL", "עברית", "Hebrew, עברית (ישראל)"],
  ["IR", "fa-IR", "فارسی", "فارسی (ایران)"],
  ["ET", "am-ET", "አማርኛ", "አማርኛ (ኢትዮጵያ)"],
  ["IN", "hi-IN", "हिन्दी", "हिन्दी (भारत), Hindi, India"],

  ["MI", "mr-IN", "मराठी", "मराठी (भारत), Marathi, India"],
  ["BN", "bn-IN", "বাংলা", "বাংলা (ভারত), Bengali, India"],
  ["GJ", "gu-IN", "ગુજરાતી", "ગુજરાતી (ભારત), Gujarati, India"],
  ["TA", "ta-IN", "தமிழ்", "தமிழ் (இந்தியா), Tamil, India"],
  ["TE", "te-IN", "తెలుగు", "తెలుగు (భారతదేశం), Telugu, India"],
  ["KA", "kn-IN", "ಕನ್ನಡ", "ಕನ್ನಡ (ಭಾರತ), Kannada, India"],
  ["MB", "ml-IN", "മലയാളം", "മലയാളം (ഇന്ത്യ), Malayalam, India"],

  ["TH", "th-TH", "ไทย", "ไทย (ไทย), Thai"],
  ["KR", "ko-KR", "한국어", "한국어(대한민국), Korean, Korea"],
  ["JP", "ja-JP", "日本語", "日本語 (日本), Japanese, Japan"],
];

/**
 * Saved output from getLangSupportList
 * No need to run that all the time...
 */
export const staticLangSupportList: SupportedLang[] = [
  ["af-ZA", "Afrikaans (Suid-Afrika)"],
  ["sq-AL", "Albanian (Albania)"],
  ["en-US", "American English"],
  ["hy-AM", "Armenian (Armenia)"],
  ["en-AU", "Australian English"],
  ["az-AZ", "Azərbaycan (Azerbaijan)"],
  ["eu-ES", "Basque (Spain)"],
  ["bs-BA", "Bosanski (Bosnia & Herzegovina)"],
  ["en-GB", "British English"],
  ["my-MM", "Burmese (Myanmar [Burma])"],
  ["en-CA", "Canadian English"],
  ["ca-ES", "Català (Espanya)"],
  ["cs-CZ", "Čeština (Česko)"],
  ["da-DK", "Dansk (Danmark)"],
  ["de-DE", "Deutsch (Deutschland)"],
  ["et-EE", "Eesti (Eesti)"],
  ["en-HK", "English (Hong Kong)"],
  ["en-IN", "English (India)"],
  ["en-IE", "English (Ireland)"],
  ["en-KE", "English (Kenya)"],
  ["en-NZ", "English (New Zealand)"],
  ["en-NG", "English (Nigeria)"],
  ["en-PH", "English (Philippines)"],
  ["en-SG", "English (Singapore)"],
  ["en-ZA", "English (South Africa)"],
  ["en-TZ", "English (Tanzania)"],
  ["es-AR", "Español (Argentina)"],
  ["es-BO", "Español (Bolivia)"],
  ["es-CL", "Español (Chile)"],
  ["es-CO", "Español (Colombia)"],
  ["es-CR", "Español (Costa Rica)"],
  ["es-CU", "Español (Cuba)"],
  ["es-EC", "Español (Ecuador)"],
  ["es-SV", "Español (El Salvador)"],
  ["es-US", "Español (Estados Unidos)"],
  ["es-GT", "Español (Guatemala)"],
  ["es-GQ", "Español (Guinea Ecuatorial)"],
  ["es-HN", "Español (Honduras)"],
  ["es-NI", "Español (Nicaragua)"],
  ["es-PA", "Español (Panamá)"],
  ["es-PY", "Español (Paraguay)"],
  ["es-PE", "Español (Perú)"],
  ["es-PR", "Español (Puerto Rico)"],
  ["es-DO", "Español (República Dominicana)"],
  ["es-UY", "Español (Uruguay)"],
  ["es-VE", "Español (Venezuela)"],
  ["es-ES", "Español de España"],
  ["es-MX", "Español de México"],
  ["fil-PH", "Filipino (Pilipinas)"],
  ["fr-BE", "Français (Belgique)"],
  ["fr-FR", "Français (France)"],
  ["fr-CA", "Français canadien"],
  ["fr-CH", "Français suisse"],
  ["gl-ES", "Galician (Spain)"],
  ["ka-GE", "Georgian (Georgia)"],
  ["hr-HR", "Hrvatski (Hrvatska)"],
  ["is-IS", "Icelandic (Iceland)"],
  ["id-ID", "Indonesian (Indonesia)"],
  ["ga-IE", "Irish (Ireland)"],
  ["it-IT", "Italiano (Italia)"],
  ["jv-ID", "Javanese (Indonesia)"],
  ["kk-KZ", "Kazakh (Kazakhstan)"],
  ["km-KH", "Khmer (Cambodia)"],
  ["sw-KE", "Kiswahili (Kenya)"],
  ["sw-TZ", "Kiswahili (Tanzania)"],
  ["lo-LA", "Lao (Laos)"],
  ["lv-LV", "Latviešu (Latvija)"],
  ["lt-LT", "Lietuvių (Lietuva)"],
  ["mk-MK", "Macedonian (North Macedonia)"],
  ["hu-HU", "Magyar (Magyarország)"],
  ["mt-MT", "Maltese (Malta)"],
  ["ms-MY", "Melayu (Malaysia)"],
  ["mn-MN", "Mongolian (Mongolia)"],
  ["nl-NL", "Nederlands (Nederland)"],
  ["ne-NP", "Nepali (Nepal)"],
  ["nb-NO", "Norsk bokmål (Norge)"],
  ["uz-UZ", "O‘zbek (Uzbekistan)"],
  ["de-AT", "Österreichisches Deutsch"],
  ["ps-AF", "Pashto (Afghanistan)"],
  ["pl-PL", "Polski (Polska)"],
  ["pt-BR", "Português (Brasil)"],
  ["pt-PT", "Português europeu"],
  ["ro-RO", "Română (România)"],
  ["de-CH", "Schweizer Hochdeutsch"],
  ["si-LK", "Sinhala (Sri Lanka)"],
  ["sk-SK", "Slovenčina (Slovensko)"],
  ["sl-SI", "Slovenščina (Slovenija)"],
  ["so-SO", "Somali (Somalia)"],
  ["fi-FI", "Suomi (Suomi)"],
  ["sv-SE", "Svenska (Sverige)"],
  ["vi-VN", "Tiếng Việt (Việt Nam)"],
  ["tr-TR", "Türkçe (Türkiye)"],
  ["nl-BE", "Vlaams"],
  ["cy-GB", "Welsh (United Kingdom)"],
  ["zu-ZA", "Zulu (South Africa)"],
  ["el-GR", "Ελληνικά (Ελλάδα)"],
  ["bg-BG", "Български (България)"],
  ["ru-RU", "Русский (Россия)"],
  ["sr-RS", "Српски (Србија)"],
  ["uk-UA", "Українська (Україна)"],
  ["he-IL", "עברית (ישראל)"],
  ["ar-JO", "العربية (الأردن)"],
  ["ar-AE", "العربية (الإمارات العربية المتحدة)"],
  ["ar-BH", "العربية (البحرين)"],
  ["ar-DZ", "العربية (الجزائر)"],
  ["ar-IQ", "العربية (العراق)"],
  ["ar-KW", "العربية (الكويت)"],
  ["ar-MA", "العربية (المغرب)"],
  ["ar-SA", "العربية (المملكة العربية السعودية)"],
  ["ar-YE", "العربية (اليمن)"],
  ["ar-TN", "العربية (تونس)"],
  ["ar-SY", "العربية (سوريا)"],
  ["ar-OM", "العربية (عُمان)"],
  ["ar-QA", "العربية (قطر)"],
  ["ar-LB", "العربية (لبنان)"],
  ["ar-LY", "العربية (ليبيا)"],
  ["ar-EG", "العربية (مصر)"],
  ["fa-IR", "فارسی (ایران)"],
  ["am-ET", "አማርኛ (ኢትዮጵያ)"],
  ["mr-IN", "मराठी (भारत)"],
  ["hi-IN", "हिन्दी (भारत)"],
  ["bn-IN", "বাংলা (ভারত)"],
  ["gu-IN", "ગુજરાતી (ભારત)"],
  ["ta-IN", "தமிழ் (இந்தியா)"],
  ["te-IN", "తెలుగు (భారతదేశం)"],
  ["kn-IN", "ಕನ್ನಡ (ಭಾರತ)"],
  ["ml-IN", "മലയാളം (ഇന്ത്യ)"],
  ["th-TH", "ไทย (ไทย)"],
  ["ko-KR", "한국어(대한민국)"],
  ["zh-CN", "中文（中国）"],
  ["zh-TW", "中文（台灣）"],
  ["zh-HK", "中文（香港）"],
  ["ja-JP", "日本語 (日本)"],
];

export const groupedLangList: [string, string, "language" | "locale"][] = [
  ["en", "English", "language"],
  ["en-US", "American English", "locale"],
  ["en-AU", "Australian English", "locale"],
  ["en-CA", "Canadian English", "locale"],
  ["en-GB", "British English", "locale"],
  ["en-HK", "English (Hong Kong)", "locale"],
  ["en-IE", "English (Ireland)", "locale"],
  ["en-IN", "English (India)", "locale"],
  ["en-KE", "English (Kenya)", "locale"],
  ["en-NG", "English (Nigeria)", "locale"],
  ["en-NZ", "English (New Zealand)", "locale"],
  ["en-PH", "English (Philippines)", "locale"],
  ["en-SG", "English (Singapore)", "locale"],
  ["en-TZ", "English (Tanzania)", "locale"],
  ["en-ZA", "English (South Africa)", "locale"],
  ["es", "Español (Spanish)", "language"],
  ["es-AR", "Español (Argentina)", "locale"],
  ["es-BO", "Español (Bolivia)", "locale"],
  ["es-CL", "Español (Chile)", "locale"],
  ["es-CO", "Español (Colombia)", "locale"],
  ["es-CR", "Español (Costa Rica)", "locale"],
  ["es-CU", "Español (Cuba)", "locale"],
  ["es-DO", "Español (República Dominicana)", "locale"],
  ["es-EC", "Español (Ecuador)", "locale"],
  ["es-ES", "Español de España", "locale"],
  ["es-GQ", "Español (Guinea Ecuatorial)", "locale"],
  ["es-GT", "Español (Guatemala)", "locale"],
  ["es-HN", "Español (Honduras)", "locale"],
  ["es-MX", "Español de México", "locale"],
  ["es-NI", "Español (Nicaragua)", "locale"],
  ["es-PA", "Español (Panamá)", "locale"],
  ["es-PE", "Español (Perú)", "locale"],
  ["es-PR", "Español (Puerto Rico)", "locale"],
  ["es-PY", "Español (Paraguay)", "locale"],
  ["es-SV", "Español (El Salvador)", "locale"],
  ["es-US", "Español (Estados Unidos)", "locale"],
  ["es-UY", "Español (Uruguay)", "locale"],
  ["es-VE", "Español (Venezuela)", "locale"],
  ["fr", "Français (French)", "language"],
  ["fr-BE", "Français (Belgique)", "locale"],
  ["fr-CA", "Français canadien", "locale"],
  ["fr-CH", "Français suisse", "locale"],
  ["fr-FR", "Français (France)", "locale"],
  ["de", "Deutsch (German)", "language"],
  ["de-AT", "Österreichisches Deutsch", "locale"],
  ["de-CH", "Schweizer Hochdeutsch", "locale"],
  ["de-DE", "Deutsch (Deutschland)", "locale"],
  ["zh", "中文 (Chinese)", "language"],
  ["zh-CN", "中文（中国）", "locale"],
  ["zh-HK", "中文（香港）", "locale"],
  ["zh-TW", "中文（台灣）", "locale"],
  ["ar", "العربية (Arabic)", "language"],
  ["ar-AE", "العربية (الإمارات العربية المتحدة)", "locale"],
  ["ar-BH", "العربية (البحرين)", "locale"],
  ["ar-DZ", "العربية (الجزائر)", "locale"],
  ["ar-EG", "العربية (مصر)", "locale"],
  ["ar-IQ", "العربية (العراق)", "locale"],
  ["ar-JO", "العربية (الأردن)", "locale"],
  ["ar-KW", "العربية (الكويت)", "locale"],
  ["ar-LB", "العربية (لبنان)", "locale"],
  ["ar-LY", "العربية (ليبيا)", "locale"],
  ["ar-MA", "العربية (المغرب)", "locale"],
  ["ar-OM", "العربية (عُمان)", "locale"],
  ["ar-QA", "العربية (قطر)", "locale"],
  ["ar-SA", "العربية (المملكة العربية السعودية)", "locale"],
  ["ar-SY", "العربية (سوريا)", "locale"],
  ["ar-TN", "العربية (تونس)", "locale"],
  ["ar-YE", "العربية (اليمن)", "locale"],
  ["nl", "Nederlands (Dutch)", "language"],
  ["nl-BE", "Vlaams", "locale"],
  ["nl-NL", "Nederlands (Nederland)", "locale"],
  ["pt", "Português (Portuguese)", "language"],
  ["pt-BR", "Português (Brasil)", "locale"],
  ["pt-PT", "Português europeu", "locale"],
  ["sw", "Kiswahili (Swahili)", "language"],
  ["sw-KE", "Kiswahili (Kenya)", "locale"],
  ["sw-TZ", "Kiswahili (Tanzania)", "locale"],
  ["x", "More", "language"],
  ["af-ZA", "Afrikaans", "locale"],
  ["am-ET", "አማርኛ (Amharic)", "locale"],
  ["az-AZ", "Azərbaycan (Azerbaijani)", "locale"],
  ["bg-BG", "Български (Bulgarian)", "locale"],
  ["bn-IN", "বাংলা (Bangla)", "locale"],
  ["bs-BA", "Bosanski (Bosnian)", "locale"],
  ["ca-ES", "Català (Catalan)", "locale"],
  ["cs-CZ", "Čeština (Czech)", "locale"],
  ["cy-GB", "Welsh", "locale"],
  ["da-DK", "Dansk (Danish)", "locale"],
  ["el-GR", "Ελληνικά (Greek)", "locale"],
  ["et-EE", "Eesti (Estonian)", "locale"],
  ["eu-ES", "Basque", "locale"],
  ["fa-IR", "فارسی (Persian)", "locale"],
  ["fi-FI", "Suomi (Finnish)", "locale"],
  ["fil-PH", "Filipino", "locale"],
  ["ga-IE", "Irish", "locale"],
  ["gl-ES", "Galician", "locale"],
  ["gu-IN", "ગુજરાતી (Gujarati)", "locale"],
  ["he-IL", "עברית (Hebrew)", "locale"],
  ["hi-IN", "हिन्दी (Hindi)", "locale"],
  ["hr-HR", "Hrvatski (Croatian)", "locale"],
  ["hu-HU", "Magyar (Hungarian)", "locale"],
  ["hy-AM", "Armenian", "locale"],
  ["id-ID", "Indonesian (Indonesian)", "locale"],
  ["is-IS", "Icelandic", "locale"],
  ["it-IT", "Italiano (Italian)", "locale"],
  ["ja-JP", "日本語 (Japanese)", "locale"],
  ["jv-ID", "Javanese", "locale"],
  ["ka-GE", "Georgian", "locale"],
  ["kk-KZ", "Kazakh", "locale"],
  ["km-KH", "Khmer", "locale"],
  ["kn-IN", "ಕನ್ನಡ (Kannada)", "locale"],
  ["ko-KR", "한국어 (Korean)", "locale"],
  ["lo-LA", "Lao", "locale"],
  ["lt-LT", "Lietuvių (Lithuanian)", "locale"],
  ["lv-LV", "Latviešu (Latvian)", "locale"],
  ["mk-MK", "Macedonian", "locale"],
  ["ml-IN", "മലയാളം (Malayalam)", "locale"],
  ["mn-MN", "Mongolian", "locale"],
  ["mr-IN", "मराठी (Marathi)", "locale"],
  ["ms-MY", "Melayu (Malay)", "locale"],
  ["mt-MT", "Maltese", "locale"],
  ["my-MM", "Burmese", "locale"],
  ["nb-NO", "Norsk bokmål (Norwegian Bokmål)", "locale"],
  ["ne-NP", "Nepali", "locale"],
  ["pl-PL", "Polski (Polish)", "locale"],
  ["ps-AF", "Pashto", "locale"],
  ["ro-RO", "Română (Romanian)", "locale"],
  ["ru-RU", "Русский (Russian)", "locale"],
  ["si-LK", "Sinhala", "locale"],
  ["sk-SK", "Slovenčina (Slovak)", "locale"],
  ["sl-SI", "Slovenščina (Slovenian)", "locale"],
  ["so-SO", "Somali", "locale"],
  ["sq-AL", "Albanian", "locale"],
  ["sr-RS", "Српски (Serbian)", "locale"],
  ["sv-SE", "Svenska (Swedish)", "locale"],
  ["ta-IN", "தமிழ் (Tamil)", "locale"],
  ["te-IN", "తెలుగు (Telugu)", "locale"],
  ["th-TH", "ไทย (Thai)", "locale"],
  ["tr-TR", "Türkçe (Turkish)", "locale"],
  ["uk-UA", "Українська (Ukrainian)", "locale"],
  ["uz-UZ", "O‘zbek (Uzbek)", "locale"],
  ["vi-VN", "Tiếng Việt (Vietnamese)", "locale"],
  ["zu-ZA", "Zulu", "locale"],
];

export const shortGroupedLangList: [string, string, "language" | "locale"][] = [
  ["en", "English", "language"],
  ["en-US", "English (US)", "locale"],
  ["en-GB", "English (GB)", "locale"],
  ["es", "Español (Spanish)", "language"],
  ["es-ES", "Español de España", "locale"],
  ["es-MX", "Español de México", "locale"],
  ["zh", "中文 (Chinese)", "language"],
  ["zh-CN", "中文（中国）", "locale"],
  ["zh-HK", "中文（香港）", "locale"],
  ["zh-TW", "中文（台灣）", "locale"],
  ["fr", "Français (French)", "language"],
  ["fr-FR", "Français (France)", "locale"],
  ["fr-CA", "Français (Canada)", "locale"],
  ["de", "Deutsch (German)", "language"],
  ["de-DE", "Deutsch (Deutschland)", "locale"],
  ["de-AT", "Österreichisches Deutsch", "locale"],
  ["de-CH", "Schweizer Hochdeutsch", "locale"],
  ["ar", "العربية (Arabic)", "language"],
  ["ar-DZ", "العربية (الجزائر)", "locale"],
  ["ar-EG", "العربية (مصر)", "locale"],
  ["ar-SA", "العربية (المملكة العربية السعودية)", "locale"],
  ["pt", "Português (Portuguese)", "language"],
  ["pt-BR", "Português (Brasil)", "locale"],
  ["pt-PT", "Português europeu", "locale"],
  ["sw", "Kiswahili (Swahili)", "language"],
  ["sw-KE", "Kiswahili (Kenya)", "locale"],
  ["sw-TZ", "Kiswahili (Tanzania)", "locale"],
  ["nl", "Nederlands (Dutch)", "language"],
  ["nl-BE", "Vlaams", "locale"],
  ["nl-NL", "Nederlands (Nederland)", "locale"],
  ["x", "More", "language"],
  ["af-ZA", "Afrikaans", "locale"],
  ["am-ET", "አማርኛ (Amharic)", "locale"],
  ["az-AZ", "Azərbaycan (Azerbaijani)", "locale"],
  ["bg-BG", "Български (Bulgarian)", "locale"],
  ["bn-IN", "বাংলা (Bangla)", "locale"],
  ["bs-BA", "Bosanski (Bosnian)", "locale"],
  ["ca-ES", "Català (Catalan)", "locale"],
  ["cs-CZ", "Čeština (Czech)", "locale"],
  ["cy-GB", "Welsh", "locale"],
  ["da-DK", "Dansk (Danish)", "locale"],
  ["el-GR", "Ελληνικά (Greek)", "locale"],
  ["et-EE", "Eesti (Estonian)", "locale"],
  ["eu-ES", "Basque", "locale"],
  ["fa-IR", "فارسی (Persian)", "locale"],
  ["fi-FI", "Suomi (Finnish)", "locale"],
  ["fil-PH", "Filipino", "locale"],
  ["ga-IE", "Irish", "locale"],
  ["gl-ES", "Galician", "locale"],
  ["gu-IN", "ગુજરાતી (Gujarati)", "locale"],
  ["he-IL", "עברית (Hebrew)", "locale"],
  ["hi-IN", "हिन्दी (Hindi)", "locale"],
  ["hr-HR", "Hrvatski (Croatian)", "locale"],
  ["hu-HU", "Magyar (Hungarian)", "locale"],
  ["hy-AM", "Armenian", "locale"],
  ["id-ID", "Indonesian (Indonesian)", "locale"],
  ["is-IS", "Icelandic", "locale"],
  ["it-IT", "Italiano (Italian)", "locale"],
  ["ja-JP", "日本語 (Japanese)", "locale"],
  ["jv-ID", "Javanese", "locale"],
  ["ka-GE", "Georgian", "locale"],
  ["kk-KZ", "Kazakh", "locale"],
  ["km-KH", "Khmer", "locale"],
  ["kn-IN", "ಕನ್ನಡ (Kannada)", "locale"],
  ["ko-KR", "한국어 (Korean)", "locale"],
  ["lo-LA", "Lao", "locale"],
  ["lt-LT", "Lietuvių (Lithuanian)", "locale"],
  ["lv-LV", "Latviešu (Latvian)", "locale"],
  ["mk-MK", "Macedonian", "locale"],
  ["ml-IN", "മലയാളം (Malayalam)", "locale"],
  ["mn-MN", "Mongolian", "locale"],
  ["mr-IN", "मराठी (Marathi)", "locale"],
  ["ms-MY", "Melayu (Malay)", "locale"],
  ["mt-MT", "Maltese", "locale"],
  ["my-MM", "Burmese", "locale"],
  ["nb-NO", "Norsk bokmål (Norwegian Bokmål)", "locale"],
  ["ne-NP", "Nepali", "locale"],
  ["pl-PL", "Polski (Polish)", "locale"],
  ["ps-AF", "Pashto", "locale"],
  ["ro-RO", "Română (Romanian)", "locale"],
  ["ru-RU", "Русский (Russian)", "locale"],
  ["si-LK", "Sinhala", "locale"],
  ["sk-SK", "Slovenčina (Slovak)", "locale"],
  ["sl-SI", "Slovenščina (Slovenian)", "locale"],
  ["so-SO", "Somali", "locale"],
  ["sq-AL", "Albanian", "locale"],
  ["sr-RS", "Српски (Serbian)", "locale"],
  ["sv-SE", "Svenska (Swedish)", "locale"],
  ["ta-IN", "தமிழ் (Tamil)", "locale"],
  ["te-IN", "తెలుగు (Telugu)", "locale"],
  ["th-TH", "ไทย (Thai)", "locale"],
  ["tr-TR", "Türkçe (Turkish)", "locale"],
  ["uk-UA", "Українська (Ukrainian)", "locale"],
  ["uz-UZ", "O‘zbek (Uzbek)", "locale"],
  ["vi-VN", "Tiếng Việt (Vietnamese)", "locale"],
  ["zu-ZA", "Zulu", "locale"],
];

export const langCodes = [
  "af-ZA",
  "am-ET",
  "ar-AE",
  "ar-BH",
  "ar-DZ",
  "ar-EG",
  "ar-IQ",
  "ar-JO",
  "ar-KW",
  "ar-LB",
  "ar-LY",
  "ar-MA",
  "ar-OM",
  "ar-QA",
  "ar-SA",
  "ar-SY",
  "ar-TN",
  "ar-YE",
  "az-AZ",
  "bg-BG",
  "bn-IN",
  "bs-BA",
  "ca-ES",
  "cs-CZ",
  "cy-GB",
  "da-DK",
  "de-AT",
  "de-CH",
  "de-DE",
  "el-GR",
  "en-AU",
  "en-CA",
  "en-GB",
  "en-HK",
  "en-IE",
  "en-IN",
  "en-KE",
  "en-NG",
  "en-NZ",
  "en-PH",
  "en-SG",
  "en-TZ",
  "en-US",
  "en-ZA",
  "es-AR",
  "es-BO",
  "es-CL",
  "es-CO",
  "es-CR",
  "es-CU",
  "es-DO",
  "es-EC",
  "es-ES",
  "es-GQ",
  "es-GT",
  "es-HN",
  "es-MX",
  "es-NI",
  "es-PA",
  "es-PE",
  "es-PR",
  "es-PY",
  "es-SV",
  "es-US",
  "es-UY",
  "es-VE",
  "et-EE",
  "eu-ES",
  "fa-IR",
  "fi-FI",
  "fil-PH",
  "fr-BE",
  "fr-CA",
  "fr-CH",
  "fr-FR",
  "ga-IE",
  "gl-ES",
  "gu-IN",
  "he-IL",
  "hi-IN",
  "hr-HR",
  "hu-HU",
  "hy-AM",
  "id-ID",
  "is-IS",
  "it-IT",
  "ja-JP",
  "jv-ID",
  "ka-GE",
  "kk-KZ",
  "km-KH",
  "kn-IN",
  "ko-KR",
  "lo-LA",
  "lt-LT",
  "lv-LV",
  "mk-MK",
  "ml-IN",
  "mn-MN",
  "mr-IN",
  "ms-MY",
  "mt-MT",
  "my-MM",
  "nb-NO",
  "ne-NP",
  "nl-BE",
  "nl-NL",
  "pl-PL",
  "ps-AF",
  "pt-BR",
  "pt-PT",
  "ro-RO",
  "ru-RU",
  "si-LK",
  "sk-SK",
  "sl-SI",
  "so-SO",
  "sq-AL",
  "sr-RS",
  "sv-SE",
  "sw-KE",
  "sw-TZ",
  "ta-IN",
  "te-IN",
  "th-TH",
  "tr-TR",
  "uk-UA",
  "uz-UZ",
  "vi-VN",
  "zh-CN",
  "zh-HK",
  "zh-TW",
  "zu-ZA",
];

export const nonLatinLanguages = [
  "el-GR",
  "bg-BG",
  "ru-RU",
  "sr-RS",
  "uk-UA",
  "he-IL",
  "ar-JO",
  "ar-AE",
  "ar-BH",
  "ar-DZ",
  "ar-IQ",
  "ar-KW",
  "ar-MA",
  "ar-SA",
  "ar-YE",
  "ar-TN",
  "ar-SY",
  "ar-OM",
  "ar-QA",
  "ar-LB",
  "ar-LY",
  "ar-EG",
  "fa-IR",
  "am-ET",
  "mr-IN",
  "hi-IN",
  "bn-IN",
  "gu-IN",
  "ta-IN",
  "te-IN",
  "kn-IN",
  "ml-IN",
  "th-TH",
  "ko-KR",
  "zh-CN",
  "zh-TW",
  "zh-HK",
  "ja-JP",
];

export const nonLatinLanguagesISO639_3 = [
  "ell", // Greek
  "bul", // Bulgarian
  "rus", // Russian
  "srp", // Serbian
  "ukr", // Ukrainian
  "heb", // Hebrew
  "ara", // Arabic (General code for all Arabic dialects)
  "ara", // Arabic (United Arab Emirates)
  "ara", // Arabic (Bahrain)
  "ara", // Arabic (Algeria)
  "ara", // Arabic (Iraq)
  "ara", // Arabic (Kuwait)
  "ara", // Arabic (Morocco)
  "ara", // Arabic (Saudi Arabia)
  "ara", // Arabic (Yemen)
  "ara", // Arabic (Tunisia)
  "ara", // Arabic (Syria)
  "ara", // Arabic (Oman)
  "ara", // Arabic (Qatar)
  "ara", // Arabic (Lebanon)
  "ara", // Arabic (Libya)
  "ara", // Arabic (Egypt)
  "fas", // Persian
  "amh", // Amharic
  "mar", // Marathi
  "hin", // Hindi
  "ben", // Bengali
  "guj", // Gujarati
  "tam", // Tamil
  "tel", // Telugu
  "kan", // Kannada
  "mal", // Malayalam
  "tha", // Thai
  "kor", // Korean
  "zho", // Chinese (General code for all Chinese dialects)
  "zho", // Chinese (Taiwan)
  "zho", // Chinese (Hong Kong)
  "jpn", // Japanese
];

export const nonSpaceSeparatedLanguages = ["zh-CN", "zh-TW", "zh-HK", "ja-JP", "th-TH", "ko-KR"];

export const formalHonorificLangCodes: string[] = [
  "de-DE", // German (Germany)
  "de-AT", // German (Austria)
  "de-CH", // Swiss German
  "fr-FR", // French (France)
  "fr-CA", // French (Canada)
  "it-IT", // Italian (Italy)
  "ja-JP", // Japanese (Japan)
  "ko-KR", // Korean (South Korea)
  "ru-RU", // Russian (Russia)
  "zh-CN", // Chinese (China)
  "zh-TW", // Chinese (Taiwan)
  "zh-HK", // Chinese (Hong Kong)
  "ar-JO", // Arabic (Jordan)
  "ar-AE", // Arabic (UAE)
  "ar-BH", // Arabic (Bahrain)
  "ar-DZ", // Arabic (Algeria)
  "ar-IQ", // Arabic (Iraq)
  "ar-KW", // Arabic (Kuwait)
  "ar-MA", // Arabic (Morocco)
  "ar-SA", // Arabic (Saudi Arabia)
  "ar-YE", // Arabic (Yemen)
  "ar-TN", // Arabic (Tunisia)
  "ar-SY", // Arabic (Syria)
  "ar-OM", // Arabic (Oman)
  "ar-QA", // Arabic (Qatar)
  "ar-LB", // Arabic (Lebanon)
  "ar-LY", // Arabic (Libya)
  "ar-EG", // Arabic (Egypt)
  "fa-IR", // Persian (Iran)
  "vi-VN", // Vietnamese (Vietnam)
  "th-TH", // Thai (Thailand)
];

export const languageGreetings = [
  ["af-ZA", "Goeie dag, lekker om jou te ontmoet. Kom ons gesels."],
  ["sq-AL", "Përshëndetje, kënaqësi që ju takoj. Le të kemi një bisedë."],
  ["en-US", "Hi, nice to meet you. Let's have a conversation."],
  ["hy-AM", "Բարև ձեզ, հաճելի է հանդիպել։ Եկեք զրուցենք։"],
  ["en-AU", "Hi, nice to meet you. Let's have a conversation."],
  ["az-AZ", "Salam, tanış olduğumuza şadam. Gəlin söhbət edək."],
  ["eu-ES", "Kaixo, pozten naiz zuekin elkartzeaz. Hitz egingo dugu."],
  ["bs-BA", "Zdravo, drago mi je što smo se upoznali. Hajde da razgovaramo."],
  ["en-GB", "Hi, nice to meet you. Let's have a conversation."],
  ["my-MM", "မင်္ဂလာပါ၊ တွေ့ရတာဝမ်းသာပါတယ်။ ဆွေးနွေးကြပါစို့။"],
  ["en-CA", "Hi, nice to meet you. Let's have a conversation."],
  ["ca-ES", "Hola, encantat de conèixer-te. Parlem una mica."],
  ["cs-CZ", "Ahoj, rád vás poznávám. Pojďme si popovídat."],
  ["da-DK", "Hej, rart at møde dig. Lad os have en samtale."],
  ["de-DE", "Hallo, freut mich, Sie kennenzulernen. Lassen Sie uns ein Gespräch führen."],
  ["et-EE", "Tere, meeldiv tutvuda. Räägime veidi."],
  ["en-HK", "Hi, nice to meet you. Let's have a conversation."],
  ["en-IN", "Hi, nice to meet you. Let's have a conversation."],
  ["en-IE", "Hi, nice to meet you. Let's have a conversation."],
  ["en-KE", "Hi, nice to meet you. Let's have a conversation."],
  ["en-NZ", "Hi, nice to meet you. Let's have a conversation."],
  ["en-NG", "Hi, nice to meet you. Let's have a conversation."],
  ["en-PH", "Hi, nice to meet you. Let's have a conversation."],
  ["en-SG", "Hi, nice to meet you. Let's have a conversation."],
  ["en-ZA", "Hi, nice to meet you. Let's have a conversation."],
  ["en-TZ", "Hi, nice to meet you. Let's have a conversation."],
  ["es-AR", "Hola, un gusto conocerte. Hablemos un poco."],
  ["es-BO", "Hola, un gusto conocerte. Hablemos un poco."],
  ["es-CL", "Hola, un gusto conocerte. Hablemos un poco."],
  ["es-CO", "Hola, un gusto conocerte. Hablemos un poco."],
  ["es-CR", "Hola, un gusto conocerte. Hablemos un poco."],
  ["es-CU", "Hola, un gusto conocerte. Hablemos un poco."],
  ["es-EC", "Hola, un gusto conocerte. Hablemos un poco."],
  ["es-SV", "Hola, un gusto conocerte. Hablemos un poco."],
  ["es-US", "Hola, un gusto conocerte. Hablemos un poco."],
  ["es-GT", "Hola, un gusto conocerte. Hablemos un poco."],
  ["es-GQ", "Hola, un gusto conocerte. Hablemos un poco."],
  ["es-HN", "Hola, un gusto conocerte. Hablemos un poco."],
  ["es-NI", "Hola, un gusto conocerte. Hablemos un poco."],
  ["es-PA", "Hola, un gusto conocerte. Hablemos un poco."],
  ["es-PY", "Hola, un gusto conocerte. Hablemos un poco."],
  ["es-PE", "Hola, un gusto conocerte. Hablemos un poco."],
  ["es-PR", "Hola, un gusto conocerte. Hablemos un poco."],
  ["es-DO", "Hola, un gusto conocerte. Hablemos un poco."],
  ["es-UY", "Hola, un gusto conocerte. Hablemos un poco."],
  ["es-VE", "Hola, un gusto conocerte. Hablemos un poco."],
  ["es-ES", "Hola, un placer conocerte. Hablemos un poco."],
  ["es-MX", "Hola, un placer conocerte. Hablemos un poco."],
  ["fil-PH", "Kumusta, ikinagagalak kitang makilala. Mag-usap tayo."],
  ["fr-BE", "Bonjour, enchanté de vous rencontrer. Parlons un peu."],
  ["fr-FR", "Bonjour, enchanté de faire votre connaissance. Parlons un peu."],
  ["fr-CA", "Bonjour, enchanté de vous rencontrer. Parlons un peu."],
  ["fr-CH", "Bonjour, enchanté de vous rencontrer. Parlons un peu."],
  ["gl-ES", "Ola, encantado de coñecerte. Falemos un pouco."],
  ["ka-GE", "გამარჯობა, სასიამოვნოა თქვენი გაცნობა. მოდით ვისაუბროთ."],
  ["hr-HR", "Bok, drago mi je upoznati vas. Razgovarajmo malo."],
  ["is-IS", "Halló, gaman að hitta þig. Ræðum saman."],
  ["id-ID", "Hai, senang bertemu dengan Anda. Mari kita berbicara."],
  ["ga-IE", "Dia dhuit, is aoibhinn liom tú a fheiceáil. Cuirimis comhrá ar bun."],
  ["it-IT", "Ciao, piacere di conoscerti. Parliamo un po'."],
  ["jv-ID", "Halo, seneng ketemu karo sampeyan. Ayo padha ngobrol."],
  ["kk-KZ", "Сәлеметсіз бе, танысқаныма қуаныштымын. Әңгімелесейік."],
  ["km-KH", "សួស្តី ខ្ញុំមានចម្ងល់ក្នុងការជួបអ្នក។ យើងនិយាយអ្វីមួយតាមផ្លូវស្តីទាំងអស់។"],
  ["sw-KE", "Hujambo, furaha kukutana nawe. Tuongee."],
  ["sw-TZ", "Hujambo, furaha kukutana nawe. Tuongee."],
  ["lo-LA", "ສະບາຍດີ, ຍິນດີທີ່ໄດ້ພົບທ່ານ. ເຮົາມາສົນທະນາກັນ."],
  ["lv-LV", "Sveiki, priecājos iepazīties. Parunāsimies."],
  ["lt-LT", "Sveiki, malonu susipažinti. Pakalbėkime."],
  ["mk-MK", "Здраво, мило ми е што ве запознав. Ајде да поразговараме."],
  ["hu-HU", "Szia, örülök, hogy találkoztunk. Beszélgessünk egy kicsit."],
  ["mt-MT", "Hello, pjaċir li niltaqgħu. Ejja niddiskutu."],
  ["ms-MY", "Hai, gembira dapat bertemu dengan anda. Mari berbual."],
  ["mn-MN", "Сайн байна уу, танилцахад таатай байна. Яриа хөөрөө хийцгээе."],
  ["nl-NL", "Hallo, leuk om je te ontmoeten. Laten we een gesprek hebben."],
  ["ne-NP", "नमस्ते, तपाईंसँग भेटेर खुशी लाग्यो। हामी कुरा गरौं।"],
  ["nb-NO", "Hei, hyggelig å møte deg. La oss ha en samtale."],
  ["uz-UZ", "Salom, tanishganimdan xursandman. Suhbatlashamizmi."],
  ["de-AT", "Hallo, freut mich, Sie kennenzulernen. Lassen Sie uns ein Gespräch führen."],
  ["ps-AF", "سلام، د تاسې سره په لیدو خوشحاله شوم. راځئ خبرې وکړو."],
  ["pl-PL", "Cześć, miło mi cię poznać. Porozmawiajmy."],
  ["pt-BR", "Oi, prazer em conhecê-lo. Vamos conversar."],
  ["pt-PT", "Olá, prazer em conhecê-lo. Vamos conversar."],
  ["ro-RO", "Salut, mă bucur să te cunosc. Hai să avem o conversație."],
  ["de-CH", "Hallo, freut mich, Sie kennenzulernen. Lassen Sie uns ein Gespräch führen."],
  ["si-LK", "ආයුබෝවන්, ඔබව හමුවීමට කටුක්සුක වේ. අපි කතා කරමු."],
  ["sk-SK", "Ahoj, rád vás poznávam. Poďme sa porozprávať."],
  ["sl-SI", "Pozdravljeni, veseli me, da vas spoznam. Pogovorimo se."],
  ["so-SO", "Salaan, farxad ayaan ku qaatay inaan ku arko. Aan wada hadalno."],
  ["fi-FI", "Hei, mukava tavata. Keskustellaan."],
  ["sv-SE", "Hej, trevligt att träffas. Låt oss samtala."],
  ["vi-VN", "Xin chào, rất vui được gặp bạn. Hãy cùng trò chuyện."],
  ["tr-TR", "Merhaba, tanıştığımıza memnun oldum. Hadi konuşalım."],
  ["nl-BE", "Hallo, leuk om je te ontmoeten. Laten we een gesprek hebben."],
  ["cy-GB", "Helo, neis cwrdd â chi. Gadewch i ni gael sgwrs."],
  ["zu-ZA", "Sawubona, kuhle ukukwazi. Asikhulume."],
  ["el-GR", "Γεια σας, χαίρομαι που σας γνωρίζω. Ας κάνουμε μια συνομιλία."],
  ["bg-BG", "Здравейте, приятно ми е да се запознаем. Нека проведем разговор."],
  ["ru-RU", "Здравствуйте, приятно познакомиться. Давайте пообщаемся."],
  ["sr-RS", "Zdravo, drago mi je što smo se upoznali. Hajde da razgovaramo."],
  ["uk-UA", "Привіт, приємно познайомитись. Давайте поспілкуємось."],
  ["he-IL", "שלום, נעים להכיר אותך. בואו נדבר."],
  ["ar-JO", "مرحبًا، تشرفت بمقابلتك. دعونا نتحدث."],
  ["fa-IR", "سلام، از ملاقات شما خوشحالم. بیا صحبت کنیم."],
  ["am-ET", "ሰላም እንደምን አለህ። እንደምን አለህ? እንናገራለን።"],
  ["hi-IN", "नमस्ते, आपसे मिलकर खुशी हुई। चलो बात करें।"],
  ["bn-IN", "হ্যালো, আপনাকে দেখে ভালো লাগলো। চলুন কথাবার্তা বলি।"],
  ["gu-IN", "નમસ્તે, તમને મળીને આનંદ થયો. આવો વાત કરીએ."],
  ["ta-IN", "வணக்கம், உங்களைச் சந்திக்க மகிழ்ச்சி. நம்மளா பேசலாம்."],
  ["te-IN", "హలో, మీరు కలవడం సంతోషం. మనం చర్చ చేద్దాం."],
  ["kn-IN", "ನಮಸ್ಕಾರ, ನಿಮ್ಮನ್ನು ಭೇಟಿಯಾಗಲು ಸಂತೋಷವಾಗಿದೆ. ಮಾತಾಡೋಣ."],
  ["ml-IN", "ഹായ്, നിന്നെ കാണാനായി സന്തോഷം. നമുക്ക് സംസാരിക്കാം."],
  ["th-TH", "สวัสดี ยินดีที่ได้พบคุณ มาคุยกันเถอะ."],
  ["ko-KR", "안녕하세요, 만나서 반갑습니다. 대화해 봅시다."],
  ["zh-CN", "你好，很高兴认识你。让我们聊聊。"],
  ["zh-TW", "你好，很高興認識你。讓我們聊聊。"],
  ["zh-HK", "你好，很高興認識你。讓我們聊聊。"],
  ["ja-JP", "こんにちは、お会いできて嬉しいです。お話ししましょう。"],
  ["ar-JO", "مرحبًا، تشرفت بمقابلتك. دعونا نتحدث."],
  ["ar-AE", "مرحبًا، تشرفت بلقائك. دعونا نتحدث."],
  ["ar-BH", "مرحبًا، تشرفت بمقابلتك. دعونا نتحدث."],
  ["ar-DZ", "مرحبًا، سعدت بلقائك. دعونا نتحدث."],
  ["ar-IQ", "مرحبًا، تشرفت بمقابلتك. دعونا نتحدث."],
  ["ar-KW", "مرحبًا، تشرفت بلقائك. دعونا نتحدث."],
  ["ar-MA", "السلام عليكم، تشرفت بلقائك. دعونا نتحدث."],
  ["ar-SA", "مرحبًا، تشرفت بلقائك. دعونا نتحدث."],
  ["ar-YE", "مرحبًا، تشرفت بلقائك. دعونا نتحدث."],
  ["ar-TN", "أهلاً، تشرفت بلقائك. دعونا نتحدث."],
  ["ar-SY", "مرحبًا، تشرفت بلقائك. دعونا نتحدث."],
  ["ar-OM", "مرحبًا، تشرفت بلقائك. دعونا نتحدث."],
  ["ar-QA", "مرحبًا، تشرفت بلقائك. دعونا نتحدث."],
  ["ar-LB", "أهلاً، تشرفت بلقائك. دعونا نتحدث."],
  ["ar-LY", "مرحبًا، تشرفت بلقائك. دعونا نتحدث."],
  ["ar-EG", "أهلاً، سعدت بلقائك. دعونا نتحدث."],
  ["mr-IN", "नमस्कार, तुम्हाला भेटून आनंद झाला. चला, आपण संभाषण करूया."],
];
