export function codeToLangName(code: string, type: "language" | "region" = "language") {
  const languageNames = new Intl.DisplayNames([code], {
    type,
  });

  return languageNames.of(code);
}

export function codeToLangNameShort(code: string, type: "language" | "region" = "language") {
  const langName = codeToLangName(code, type) ?? "Unknown language";
  const shortName = langName.replace(/\s*\([^)]*\)/g, "");
  const capitalized = shortName.charAt(0).toUpperCase() + shortName.slice(1);

  return capitalized;
}

export function codeToEnglishLangName(code: string) {
  const languageNames = new Intl.DisplayNames(["en"], { type: "language" });
  return languageNames.of(code);
}

export function codeToUserLangName(props: {
  practiceLanguage: string;
  resultLanguage: string;
  short?: boolean;
}) {
  const languageNames = new Intl.DisplayNames([props.resultLanguage], {
    type: "language",
  });

  let result = languageNames.of(props.practiceLanguage);

  // If short is true, remove text within parentheses
  if (result && props.short) {
    result = result.replace(/\s*\(.*?\)\s*/g, "");
  }

  return result;
}
