import { gregPartner } from "server/check-super-admin-access";
import { SiteConfig } from "../../types";
import { invitationTemplate } from "./invitation";

export const _config: SiteConfig = {
  isDefault: false,
  enableStripeBilling: false,
  showTidio: false,
  serviceName: "Spanish Language Center AI Teacher",
  pwaServiceName: "AI Teacher",
  appLogoPath: "/partner/spanish-languagecenter/icon.png",
  appLogoHeight: "50px",
  landingLogoPath: "/partner/spanish-languagecenter/icon.png",
  landingLogoHeight: "50px",
  privacyLink: "https://www.spanish-languagecenter.com/privacy",
  hubLogoPath: "/partner/spanish-languagecenter/icon.png",
  hubLogoHeight: "50px",
  favicon: "/partner/spanish-languagecenter/favicon.png",
  // parentService: {
  //   name: "Magyar Iskola Kft.",
  //   link: "https://www.magyar-iskola.hu/",
  // },
  pwaIcons: [
    {
      src: "/partner/spanish-languagecenter/icon.png",
      type: "image/png",
      sizes: "192x192",
    },
  ],
  fonts: {
    heading: `"Lato", "Open Sans"`,
    body: `"Lato", "Open Sans"`,
  },
  fontLink: "/partner/spanish-languagecenter/fonts.css",
  mobileLogoPath: "/partner/spanish-languagecenter/icon.png",
  emailInvitationSubject: "Welcome to the Spanish Language Center!",
  emailsSender: "info@spanish-languagecenter.com",
  emailInvitationHtml: invitationTemplate,
  adminEmail: "info@spanish-languagecenter.com",
  adminPhoneNumber: "+1 7865981688",
  whatsAppNumber: 17865981688,
  reviewLink: "www.spanish-langaugecenter.com/review",
  copyright: "Spanish Language Center. All rights reserved.",
  showLanguageGuides: true,
  overrideLanding: true,
  superAdminIds: [gregPartner],
  supportLink: "https://www.spanish-languagecenter.com/support",
  bioPrompt: `
    Welcome to the Spanish Language Center! Spanish language for everyone.
  `,
  siteColors: {
    special: {
      desktopSidebar: "#fff",
      desktopSidebarBorderColor: "#eee",
      desktopSidebarText: "#000",
      desktopSidebarTextHover: "#eee",
      chatAsideMenu: "#1D1D1D",
      mobileNavBackgroundColor: "white",
      mobileNavBackgroundContrastColor: "#000",
      mobileNavBorderColor: "#eee",
      speakInputIdle: "var(--chakra-colors-brand-primary-500)",
      speakInputRecording: "var(--chakra-colors-brand-primary-800)",
      speakInputRecordingCancel: "var(--chakra-colors-brand-primary-900)",
    },
  },
  colors: {
    primary: {
      main: "#FF5733",
      mainContrast: "#fff",
      "50": "#fff5f3",
      "100": "#ffd7cf",
      "200": "#ffb3a3",
      "300": "#ff8166",
      "400": "#ff5e3c",
      "500": "#FF5733",
      "600": "#ba3f25",
      "700": "#96331e",
      "800": "#7f2b19",
      "900": "#5c2012",
    },

    secondary: {
      main: "#4CAF50",
      mainContrast: "#fff",
      "50": "#f2f9f2",
      "100": "#cbe8cc",
      "200": "#9cd39f",
      "300": "#61b965",
      "400": "#49a74c",
      "500": "#3d8d40",
      "600": "#347736",
      "700": "#2a602c",
      "800": "#235125",
      "900": "#193a1b",
    },
    tertiary: {
      main: "#24ceff",
      mainContrast: "#000",
      "50": "#eafaff",
      "100": "#a5ebff",
      "200": "#44d5ff",
      "300": "#1fb3de",
      "400": "#1ca0c7",
      "500": "#1887a8",
      "600": "#14728e",
      "700": "#105c72",
      "800": "#0e4e60",
      "900": "#0a3846",
    },
    light: {
      main: "#f5f5f5",
      alt: "#f5f5f5",
    },
    // https://palette.saas-ui.dev/

    gray: {
      "50": "#fafafa",
      "100": "#f1f1f1",
      "200": "#e7e7e7",
      "300": "#d4d4d4",
      "400": "#adadad",
      "500": "#7f7f7f",
      "600": "#545454",
      "700": "#373737",
      "800": "#202020",
      "900": "#191919",
    },
    orange: {
      "50": "#fffaf4",
      "100": "#ffead4",
      "200": "#ffd0a1",
      "300": "#ffa952",
      "400": "#e98c2f",
      "500": "#c87828",
      "600": "#a96522",
      "700": "#87511b",
      "800": "#6a4015",
      "900": "#573411",
    },
    yellow: {
      "50": "#fffefa",
      "100": "#fff9dd",
      "200": "#ffee97",
      "300": "#ffde38",
      "400": "#eacb2f",
      "500": "#c0a726",
      "600": "#9a851f",
      "700": "#786818",
      "800": "#5a4e12",
      "900": "#4a400f",
    },
    green: {
      "50": "#effff7",
      "100": "#9affcc",
      "200": "#31f392",
      "300": "#2bd982",
      "400": "#26be72",
      "500": "#3d8d40",
      "600": "#1b8751",
      "700": "#15693f",
      "800": "#115634",
      "900": "#0e472a",
    },
    teal: {
      "50": "#e4ffff",
      "100": "#56ffff",
      "200": "#2febeb",
      "300": "#2ad2d2",
      "400": "#24b3b3",
      "500": "#1e9898",
      "600": "#197b7b",
      "700": "#136060",
      "800": "#105050",
      "900": "#0d4242",
    },
    cyan: {
      "50": "#effcff",
      "100": "#b7f3ff",
      "200": "#93edff",
      "300": "#64e5ff",
      "400": "#2dc5e3",
      "500": "#2ab5d1",
      "600": "#26a3bc",
      "700": "#1f879b",
      "800": "#1a6f80",
      "900": "#145663",
    },
    blue: {
      "50": "#eff7ff",
      "100": "#c3e1ff",
      "200": "#96caff",
      "300": "#62b0ff",
      "400": "#3296fa",
      "500": "#2b81d7",
      "600": "#246cb4",
      "700": "#1c538a",
      "800": "#174471",
      "900": "#12375c",
    },
    purple: {
      "50": "#f9f5ff",
      "100": "#e6d9ff",
      "200": "#d3bcff",
      "300": "#b692ff",
      "400": "#a273ff",
      "500": "#8547ff",
      "600": "#6e2feb",
      "700": "#5a27c2",
      "800": "#4a209f",
      "900": "#371877",
    },
    pink: {
      "50": "#fff5fa",
      "100": "#ffd6ea",
      "200": "#ffb3d9",
      "300": "#ff7fbf",
      "400": "#ff50a8",
      "500": "#e12d87",
      "600": "#c12774",
      "700": "#9e205f",
      "800": "#7c194a",
      "900": "#5b1237",
    },
    red: {
      "50": "#fff5f5",
      "100": "#ffd7d7",
      "200": "#ffb2b2",
      "300": "#ff7f7f",
      "400": "#ff5c5c",
      "500": "#ee3030",
      "600": "#ca2828",
      "700": "#a32121",
      "800": "#8a1c1c",
      "900": "#651414",
    },
  },
};
