import { useState, useEffect } from "react";
import { SupportedLang, getLangSupportList } from "../services/speech/text-to-speech-config";

export function useLanguages() {
  const [languagesAreLoading, setLanguagesAreLoading] = useState(false);
  const [languages, setLanguages] = useState<SupportedLang[]>([]);

  useEffect(() => {
    setLanguagesAreLoading(true);
    getLangSupportList().then((list) => {
      setLanguages(list);
      setLanguagesAreLoading(false);
    });
  }, []);

  return { languages, languagesAreLoading };
}
